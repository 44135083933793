@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc8WAf5q.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v8/pe0qMImSLYBIv1o4X1M8cfe6.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc9iB_5q.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc8aBf5q.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
  padding-right: 20px;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none !important;
}
body button {
  border: none;
  outline: none;
}
body button:focus {
  box-shadow: none !important;
}
body ul {
  list-style: none;
}
body::-webkit-scrollbar {
  width: 20px;
}
body::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
body::-webkit-scrollbar-thumb {
  background-color: #ddd;
}
body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 108, 0, 0.8);
}
.modal .modal-content {
  padding: 20px 30px;
}
.modal .modal-body {
  padding: 30px 15px;
}
.modal .modal-body p {
  margin-top: 10px !important;
}
.modal .modal-footer {
  padding: 20px 0 10px 0;
}
.modal .modal-footer .btn-default {
  margin-top: 0 !important;
  width: 150px;
}
.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.alert-wrapper .alert-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  transform: translateX(8px);
  padding: 50px 0;
}
.alert-wrapper .alert-service .service-inner {
  padding: 50px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-top: 5px solid #fff;
  position: relative;
  transform: scale(0.8);
  transition: all 0.5s;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss {
  top: 10px;
  right: 10px;
  position: absolute;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  padding: 5px;
  outline: none;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss img {
  height: 15px;
}
.alert-wrapper .alert-service .service-inner.success {
  border-top: 5px solid #78b042;
}
.alert-wrapper .alert-service .service-inner.warning {
  border-top: 5px solid #d51a27;
}
.alert-wrapper .alert-service .service-inner.info {
  border-top: 5px solid #1a88d5;
}
.alert-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.alert-wrapper.active .alert-service .service-inner {
  transform: none;
}
.section-form {
  margin: 60px auto 0;
  width: 75%;
  padding: 30px;
  background-color: #f5f5f5;
}
.section-form .section-heading {
  margin-bottom: 20px;
}
.section-form .form-element {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-form .form-element .input-wrapper,
.section-form .form-element input,
.section-form .form-element textarea {
  width: 100%;
  background-color: transparent;
}
.section-form .form-element label {
  margin: 30px 0 20px 0;
  text-align: left;
  font-size: 13px;
  width: 100%;
}
.section-form select {
  width: 100%;
  display: block;
  border: none;
  border-radius: 0;
  padding: 0;
  font-size: 14px;
  margin: 10px 0;
  background-color: transparent;
  cursor: pointer;
}
.section-form option {
  font-size: 14px;
  padding: 10px;
  background-color: transparent;
}
.section-form select,
.section-form textarea,
.section-form input {
  transition: all 0.2s;
  border: none;
  border-bottom: 1px solid #ff6c00;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
.section-form select:focus,
.section-form textarea:focus,
.section-form input:focus {
  outline: none;
  border-bottom: 1px solid #000;
}
.section-form textarea {
  height: 80px;
}
.section-form .info {
  margin-top: 20px;
}
.section-form .info p {
  color: #000;
  font-size: 11px;
}
.section-form .button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.section-form .formal {
  padding: 10px 10px 10px 0;
  height: 60px;
  overflow: auto;
  margin: 10px 0;
}
.section-form .formal p {
  font-size: 10px;
  line-height: 18px;
  color: #000;
}
.checkbox-wrapper {
  position: relative;
  cursor: pointer;
  display: block;
  margin-bottom: 0;
  height: 30px;
}
.checkbox-wrapper .radio-box {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  font-size: 13px;
  text-transform: none;
  color: #000;
  text-align: left;
  transition: all 0.3s;
  padding: 0;
  font-weight: 500;
}
.checkbox-wrapper .radio-box:focus {
  border: none;
}
.checkbox-wrapper .radio-box:before {
  content: "";
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  display: block;
  background: url("../img/icons/checkbox.svg") no-repeat;
  margin-right: 10px;
  transition: all 0.3s;
}
.checkbox-wrapper .radio-box p {
  font-size: 11px;
}
.checkbox-wrapper input {
  width: auto !important;
  height: auto;
  position: absolute;
  left: 0;
  opacity: 0;
}
.checkbox-wrapper input:focus {
  box-shadow: none;
}
.checkbox-wrapper > input:checked + .radio-box {
  font-weight: 700;
}
.checkbox-wrapper > input:checked + .radio-box:before {
  background: url("../img/icons/checkbox-checked.svg") no-repeat;
}
@keyframes navOpen {
  from {
    width: 0%;
    transition-timing-function: ease-in;
  }
  to {
    width: 65%;
    transition-timing-function: ease-out;
  }
}
@keyframes navClose {
  from {
    width: 65%;
    transition-timing-function: ease-in;
  }
  to {
    width: 0%;
    transition-timing-function: ease-out;
  }
}
nav.section-header {
  display: block;
  z-index: 111;
}
nav.section-header.bg-color-light {
  background-color: #f5f5f5;
}
nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav .mobile {
  display: none;
}
nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav .mobile button {
  background-color: transparent;
  color: #444;
}
nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav .desktop .dropdown-toggle {
  outline: none;
  border: none;
  font-size: 14px;
  color: #444;
  background: none;
  cursor: pointer;
  transition: color 350ms;
}
nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav .desktop .dropdown-toggle:hover {
  color: #ff6c00;
}
nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav .desktop .dropdown-toggle::after {
  display: none;
}
nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav .desktop .dropdown-menu {
  position: relative;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 0;
  margin-top: 10px;
}
nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav .desktop .dropdown-menu:before {
  content: "";
  display: block;
  position: absolute;
  left: 25px;
  top: -6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 5px 4px;
  border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
}
nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav .desktop .dropdown-menu .dropdown-item {
  padding: 8px;
  font-size: 13px;
  font-weight: 700;
  transition: all 0.3s;
  transform: none !important;
  color: #fff;
}
nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav .desktop .dropdown-menu .dropdown-item:hover {
  background: rgba(255, 255, 255, 0.1);
}
nav.section-header .header-flexbox {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
nav.section-header .header-flexbox .flexbox-logo {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-logo img {
  height: 60px;
  width: auto;
}
nav.section-header .header-flexbox .list-contact {
  display: none;
}
nav.section-header .header-flexbox .list-contact a img {
  width: 25px;
  margin: 0 10px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 70%;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome {
  position: fixed;
  top: 0;
  left: -20px;
  height: 100%;
  width: 0;
  background: #fff;
  z-index: 555;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome .nav-button-close {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome .flexbox-nav-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  padding: 50px 30px;
  margin-right: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome .flexbox-nav-inner .flexbox-nav {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome .flexbox-nav-inner .flexbox-nav li {
  padding: 10px 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome .flexbox-nav-inner .flexbox-nav li a {
  font-size: 18px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome .flexbox-nav-inner .flexbox-nav li.desktop {
  display: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome.navOpen {
  animation: navOpen 600ms both;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome.navOpen .nav-button-close {
  top: 30px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome.navClose {
  animation: navClose 600ms both;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome.navClose .nav-button-close {
  opacity: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome.navClose .flexbox-nav-inner .flexbox-nav {
  opacity: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  z-index: 22;
  display: none;
  width: 100%;
  top: 20px;
  right: 20px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close.hidden {
  display: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #222;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
  width: 100%;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  width: 100%;
  display: flex;
  justify-content: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
  padding: 0 25px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.nav-collapse {
  display: flex;
  flex-direction: column;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.nav-collapse .collapse-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.nav-collapse .collapse-list li {
  padding: 5px 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.nav-collapse .collapse-list li a {
  font-size: 15px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  color: #444444;
  font-size: 14px;
  letter-spacing: 0.5px;
  transition: color 350ms;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a:hover {
  color: #ff6c00;
}
nav.section-header .header-flexbox .flexbox-contact {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  z-index: 22;
  width: 30%;
}
nav.section-header .header-flexbox .flexbox-contact:before {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  top: -70px;
  height: 120px;
  width: 110%;
  background-color: rgba(255, 108, 0, 0.8);
}
nav.section-header .header-flexbox .flexbox-contact.hidden {
  display: none;
}
nav.section-header .header-flexbox .flexbox-contact a {
  color: #fff;
}
nav.section-header .header-flexbox .flexbox-contact a span {
  font-size: 13px;
  font-weight: 700;
  opacity: 1;
  letter-spacing: 0.5px;
  transition: color 350ms;
}
nav.section-header .header-flexbox .flexbox-contact a:hover span {
  color: #444;
}
.nav-button {
  margin-left: -10px;
  padding: 10px;
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  cursor: pointer;
}
.nav-button.hidden {
  display: none;
}
.nav-button .button-bar {
  height: 1px;
  background-color: #fff;
  width: 25px;
  border-radius: 10px;
  transition: all 350ms;
}
.nav-button .button-bar:nth-child(2) {
  margin-top: 5px;
}
.nav-button .button-bar:nth-child(3) {
  margin-top: 5px;
}
.nav-button:hover .button-bar,
.nav-button:focus .button-bar {
  background: #000;
  width: 20px;
}
.nav-button:hover .button-bar:nth-child(2),
.nav-button:focus .button-bar:nth-child(2) {
  border-color: #ff6c00;
  background: #ff6c00;
  width: 25px;
}
.nav-button:hover .button-bar:nth-child(3),
.nav-button:focus .button-bar:nth-child(3) {
  width: 28px;
}
.btn {
  background: none;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  white-space: normal;
  margin-top: 10px;
}
.btn.btn-default,
.btn .btn-default-dark {
  color: #222;
  display: flex;
  justify-content: flex-start;
  display: inline-block;
  align-self: flex-start;
}
.btn.btn-default span,
.btn .btn-default-dark span {
  display: inline-block;
  width: 22px;
  height: 1.5px;
  transform: translate(-7px, -3px);
  background-color: #ff6c00;
  transition: all 350ms;
}
.btn.btn-default:hover span,
.btn .btn-default-dark:hover span {
  width: 30px;
}
.btn.btn-default-dark {
  color: #fff;
}
.page-heading {
  background-color: #f5f5f5;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-heading h1 {
  width: 70%;
  margin: 0 auto;
  font-size: 60px;
  text-align: center;
}
.page-heading p {
  font-size: 22px;
  margin-top: 10px;
}
.section-heading {
  position: absolute;
  z-index: 11;
  left: 0;
  top: 40px;
  left: 30px;
  border-left: 1px solid #bbb;
  padding-left: 10px;
  height: 60px;
}
.section-heading span {
  color: #222;
  font-size: 15px;
  font-weight: 300;
  display: block;
}
.section-heading h2 {
  margin-top: 10px;
  color: #222;
  font-size: 28px;
  line-height: 20px;
  text-transform: uppercase;
}
.section-heading.light h2,
.section-heading.light span {
  color: #fff;
}
.heading-mobile {
  display: none;
  margin-bottom: 20px;
}
.heading-mobile h3 {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}
.heading-element h2 {
  text-align: center;
  font-size: 45px;
  font-weight: 600;
}
#return-to-top {
  position: fixed;
  z-index: 999;
  bottom: 50px;
  right: 50px;
  background-color: rgba(255, 108, 0, 0.7);
  width: 45px;
  height: 45px;
  text-decoration: none;
  display: none;
  transition: all 0.3s ease;
  cursor: pointer;
}
#return-to-top div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
#return-to-top div img {
  width: 22px;
}
#return-to-top:hover {
  background-color: #ff6c00;
}
.owl-controls .owl-dots {
  transform: rotate(90deg);
  display: flex;
  justify-content: center;
  align-items: center;
}
.owl-controls .owl-dots .owl-dot span {
  background: transparent;
  border: 1px solid #fff;
  width: 10px;
  height: 10px;
}
.owl-controls .owl-dots .owl-dot.active span {
  background: #ff6c00;
  border: none;
  width: 18px;
  height: 18px;
}
.color-element {
  position: absolute;
  z-index: -1;
  left: -15px;
  top: -15px;
  width: 150px;
  height: 150px;
  background-color: #ff6c00;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  transition: all 450ms;
}
.link-color {
  color: #fff;
  position: relative;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  width: 300px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}
.link-color .link-color-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #ff6c00;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.1);
  transition: all 350ms;
}
.link-color:hover {
  color: #fff;
}
.link-color:hover .link-color-element {
  width: 305px;
  height: 85px;
}
.news-wrapper {
  display: flex;
}
.news-wrapper .navigation {
  width: 15%;
}
.news-wrapper .navigation p {
  font-size: 17px;
  text-transform: uppercase;
  margin: 0 0 10px 0;
}
.news-wrapper .navigation ul {
  flex-direction: column;
  border: none;
}
.news-wrapper .navigation ul li {
  padding: 10px 0;
  transition: all 300ms;
}
.news-wrapper .navigation ul li a {
  color: #222;
  padding: 5px 0;
  transition: all 300ms;
}
.news-wrapper .navigation ul li a.active {
  color: #ff6c00;
}
.news-wrapper .navigation ul li:hover {
  transform: translatex(3px);
}
.news-wrapper .navigation ul li:hover a {
  color: #ff6c00;
}
.news-wrapper .news-content {
  width: 85%;
}
.news-wrapper .news-description {
  width: 50%;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.news-wrapper .news-description h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  color: #222;
  margin-bottom: 10px;
}
.news-wrapper .news-description h3:after {
  display: inline-block;
  transform: translate(10px, -8px);
  content: "";
  width: 25px;
  height: 1px;
  background-color: #222;
}
.news-wrapper .news-description p {
  font-size: 15px;
  line-height: 28px;
}
.news-wrapper .news-description .date {
  font-size: 13px;
  margin-bottom: 10px;
  font-weight: 700;
}
.news-wrapper .news-img {
  width: 50%;
}
.news-wrapper .news-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pagination-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0 0 170px;
}
.pagination-nav li {
  padding: 5px;
}
.pagination-nav li a {
  color: #222;
  padding: 10px;
}
.pagination-nav li.active {
  background-color: #ff6c00;
}
.pagination-nav li.active a {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
}
section.section-welcome {
  height: 100vh;
  position: relative;
}
section.section-welcome .nav-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 555;
}
section.section-welcome .nav-inner.sticky {
  position: fixed;
  transition: all 300ms linear;
  background-color: rgba(0, 0, 0, 0.8);
}
section.section-welcome .nav-inner.sticky .nav-button:hover .button-bar,
section.section-welcome .nav-inner.sticky .nav-button:focus .button-bar {
  background: #fff;
}
section.section-welcome .nav-inner.sticky .nav-button:hover .button-bar:nth-child(2),
section.section-welcome .nav-inner.sticky .nav-button:focus .button-bar:nth-child(2) {
  border-color: #ff6c00;
  background: #ff6c00;
}
section.section-welcome .nav-inner .nav-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
section.section-welcome .nav-inner .nav-wrapper .header-flexbox .flexbox-logo {
  width: auto;
}
section.section-welcome .welcome-background {
  position: relative;
  background: url(../img/backgrounds/bg.jpg) no-repeat;
  height: 100%;
  width: 100%;
  display: flex;
  background-size: cover;
}
section.section-welcome .welcome-background .owl-carousel .animated {
  animation-duration: 800ms;
  animation-fill-mode: both;
}
section.section-welcome .welcome-background .owl-carousel-wrapper {
  height: 300px;
  width: 450px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(0%, -50%);
  border-top: 2px solid rgba(255, 255, 255, 0.9);
  border-right: 2px solid rgba(255, 255, 255, 0.9);
  border-bottom: 2px solid rgba(255, 255, 255, 0.9);
}
section.section-welcome .welcome-background .owl-carousel-wrapper .owl-carousel-welcome {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
}
section.section-welcome .welcome-background .owl-carousel-wrapper .owl-carousel-welcome .owl-stage-outer,
section.section-welcome .welcome-background .owl-carousel-wrapper .owl-carousel-welcome .owl-stage,
section.section-welcome .welcome-background .owl-carousel-wrapper .owl-carousel-welcome .owl-item,
section.section-welcome .welcome-background .owl-carousel-wrapper .owl-carousel-welcome .owl-item-wrapper {
  height: 100%;
}
section.section-welcome .welcome-background .owl-carousel-wrapper .owl-carousel-welcome .owl-item-wrapper {
  padding: 0 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
section.section-welcome .welcome-background .owl-carousel-wrapper .owl-carousel-welcome .owl-item-wrapper p {
  font-size: 15px;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 300;
}
section.section-welcome .welcome-background .owl-carousel-wrapper .owl-carousel-welcome .owl-item-wrapper h2 {
  color: #fff;
  font-size: 32px;
}
section.section-welcome .welcome-background .owl-carousel-wrapper .owl-carousel-welcome .owl-controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(245%, -50%);
}
section.section-welcome .welcome-background .owl-carousel-wrapper .owl-carousel-welcome .owl-controls .owl-nav {
  display: none;
}
section.section-welcome .welcome-background .owl-carousel-wrapper .owl-element {
  position: absolute;
  z-index: 1;
  height: 88px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
  top: 104px;
  left: -194px;
  background-color: rgba(255, 255, 255, 0.7);
  border-top: 3px solid rgba(255, 255, 255, 0.9);
  border-left: 3px solid rgba(255, 255, 255, 0.9);
  border-right: 3px solid rgba(255, 255, 255, 0.9);
}
section.section-welcome .welcome-background .owl-carousel-wrapper .owl-element p {
  color: #ff6c00;
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
section.section-welcome .welcome-background .owl-carousel-wrapper .owl-element p strong {
  font-weight: 800;
}
section.section-welcome .welcome-background .background-left-col {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
section.section-welcome .welcome-background .background-left-col .list-social-media {
  display: flex;
  margin: 0 0 60px 30px;
}
section.section-welcome .welcome-background .background-left-col .list-social-media li {
  margin-right: 15px;
}
section.section-welcome .welcome-background .background-left-col .list-social-media li .social-media img {
  width: 40px;
}
section.section-welcome .welcome-background .background-left-col .list-social-media li:last-of-type {
  margin-right: 0;
}
section.section-welcome .welcome-background .background-right-col {
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  display: flex;
  align-items: flex-end;
}
section.section-welcome .welcome-background .background-right-col h3 {
  margin: 0 0 70px 60px;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: 2px;
  font-weight: 600;
  z-index: 1;
  color: #fff;
  text-transform: uppercase;
}
section.section-about {
  position: relative;
  padding: 100px 0;
}
section.section-about .section-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.section-about .section-wrapper .about-description {
  width: 45%;
  padding-left: 20px;
}
section.section-about .section-wrapper .about-description h3 {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 34px;
}
section.section-about .section-wrapper .about-description h3 strong {
  color: #ff6c00;
  font-size: 34px;
}
section.section-about .section-wrapper .about-description p:not(:first-of-type) {
  margin-top: 10px;
  font-size: 15px;
  line-height: 28px;
  text-align: justify;
}
section.section-about .section-wrapper .about-image {
  position: relative;
  width: 50%;
}
section.section-about .section-wrapper .about-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
section.section-about .section-wrapper .about-image:hover .color-element {
  width: 120px;
  height: 120px;
}
section.section-icons {
  padding: 60px 0;
  background-color: #f5f5f5;
}
section.section-icons .section-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.section-icons .section-wrapper .icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
section.section-icons .section-wrapper .icon h3 {
  text-align: center;
  margin-top: 20px;
  font-size: 15px;
  transition: color 350ms;
}
section.section-icons .section-wrapper .icon img {
  width: 40px;
}
section.section-icons .section-wrapper .icon .active {
  color: #ff6c00;
}
section.section-offer {
  position: relative;
  height: 600px;
}
section.section-offer .section-background {
  position: relative;
  display: flex;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
section.section-offer .section-background .owl-carousel .animated {
  animation-duration: 400ms;
  animation-fill-mode: both;
}
section.section-offer .section-background .owl-carousel-wrapper {
  height: 450px;
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer {
  width: 100%;
  height: 100%;
  z-index: 1;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-stage-outer,
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-stage,
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-item,
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-item-wrapper {
  height: 100%;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .heading {
  width: 36%;
  height: 80%;
  padding: 25px;
  border: 2px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .heading h3 {
  color: #fff;
  font-size: 40px;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .heading p {
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 1.5px;
  color: #fff;
  font-weight: 300;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .heading ul {
  text-align: left;
  padding: 20px 0 0 30px;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .heading ul li {
  padding: 5px 0;
  position: relative;
  transition: all 300ms;
  color: #fff;
  font-size: 15.5px;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .heading ul li:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  background: #ff6c00;
  top: 14px;
  left: -15px;
  transition: all 250ms;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .heading ul li:hover {
  transform: translatex(3px);
  color: #ff9e57;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .heading ul li:hover:before {
  background: #fff;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .description {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 30px;
  width: 32%;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .description h3 {
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 300;
  line-height: 26px;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .description p {
  color: #fff;
  text-align: justify;
  font-size: 15px;
  line-height: 28px;
  margin-top: 10px;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-dots {
  position: absolute;
  right: 85px;
  bottom: 220px;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-nav {
  width: 92%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-nav .owl-prev,
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-nav .owl-next {
  background: transparent;
  transition: transform 350ms;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-nav .owl-prev span,
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-nav .owl-next span {
  font-size: 12px;
  letter-spacing: 1px;
  margin: 0 5px;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-nav .owl-prev img,
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-nav .owl-next img {
  width: 30px;
  transition: opacity 350ms;
  opacity: 0.7;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-nav .owl-prev:hover img,
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-nav .owl-next:hover img {
  opacity: 1;
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-nav .owl-prev:hover {
  transform: translatex(-3px);
}
section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-nav .owl-next:hover {
  transform: translatex(3px);
}
section.section-offer .section-background .background-left-col {
  width: 50%;
  height: 100%;
  opacity: 0.4;
}
section.section-offer .section-background .background-left-col img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
section.section-offer .section-background .background-right-col {
  width: 50%;
  height: 100%;
  position: relative;
}
section.section-team {
  padding: 120px 0 100px 0;
  position: relative;
}
section.section-team .team-description {
  width: 75%;
}
section.section-team .team-description h3 {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 34px;
}
section.section-team .team-description h3 strong {
  color: #ff6c00;
  font-size: 34px;
}
section.section-team .section-wrapper {
  margin-top: 60px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
section.section-team .section-wrapper:hover .color-element {
  width: 120px;
  height: 120px;
}
section.section-team .section-wrapper .person {
  position: relative;
  width: 32.7%;
  height: 300px;
  margin: 3px;
}
section.section-team .section-wrapper .person img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: contrast(70%);
  transition: all 450ms;
  object-position: 0 20%;
}
section.section-team .section-wrapper .person .person-description {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  line-height: 18px;
  background-color: rgba(0, 0, 0, 0.5);
  letter-spacing: 1px;
  opacity: 0;
  transition: all 350ms ease;
  height: 0;
  width: 100%;
  padding: 20px 8px 5px 15px;
}
section.section-team .section-wrapper .person .person-description span.name {
  font-size: 15px;
  font-weight: 600;
  display: block;
  text-transform: uppercase;
}
section.section-team .section-wrapper .person .person-description span.name:after {
  display: inline-block;
  transform: translate(5px, -5px);
  content: "";
  width: 15px;
  height: 1px;
  background-color: #fff;
}
section.section-team .section-wrapper .person .person-description span.position {
  font-size: 14px;
  display: block;
  margin-top: 5px;
}
section.section-team .section-wrapper .person .person-description p {
  margin-top: 10px;
  font-size: 14px;
  display: none;
}
section.section-team .section-wrapper .person .person-description p a {
  color: #ff6c00;
}
section.section-team .section-wrapper .person:hover img {
  filter: contrast(100%);
}
section.section-team .section-wrapper .person:hover .person-description {
  opacity: 1;
  height: 57%;
}
section.section-team .section-wrapper .person:hover .person-description p {
  display: block;
}
section.section-team .section-wrapper .person .link {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  transition: all 350ms ease;
}
section.section-team .section-wrapper .person .link .name {
  position: absolute;
  background-color: transparent;
  color: #222;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0;
  left: 25%;
  top: 45%;
}
section.section-team .section-wrapper .person .link img {
  width: 60px;
  object-fit: contain;
  filter: contrast(100%);
  object-position: center;
}
section.section-team .section-wrapper .person .link:hover img {
  opacity: 0;
}
section.section-team .section-wrapper .person .link:hover .name {
  opacity: 1;
}
.owl-carousel-wrapper {
  display: flex;
  align-items: center;
}
.owl-carousel-wrapper .owl-carousel-partners {
  height: 150px;
  margin-top: 50px;
}
.owl-carousel-wrapper .owl-carousel-partners .owl-stage-outer,
.owl-carousel-wrapper .owl-carousel-partners .owl-stage,
.owl-carousel-wrapper .owl-carousel-partners .owl-item,
.owl-carousel-wrapper .owl-carousel-partners .owl-item-wrapper {
  height: 100%;
}
.owl-carousel-wrapper .owl-carousel-partners .owl-controls {
  display: none;
}
.owl-carousel-wrapper .owl-carousel-partners .owl-item {
  display: flex;
  justify-content: center;
}
.owl-carousel-wrapper .owl-carousel-partners .owl-item .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #222;
}
.owl-carousel-wrapper .owl-carousel-partners .owl-item .item img {
  width: 170px;
  opacity: 0.7;
  transition: opacity 350ms;
}
.owl-carousel-wrapper .owl-carousel-partners .owl-item .item p {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  font-size: 13px;
  opacity: 0;
  transition: all 350ms;
  margin-top: 15px;
}
.owl-carousel-wrapper .owl-carousel-partners .owl-item .item:hover img,
.owl-carousel-wrapper .owl-carousel-partners .owl-item .item:hover p {
  opacity: 1;
}
section.section-partners {
  position: relative;
  padding: 50px 0;
  background-color: #f5f5f5;
}
section.section-news {
  position: relative;
  padding: 150px 0 100px 0;
}
section.section-news .news-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
section.section-news .news {
  display: flex;
  flex-direction: column;
  width: 32%;
  max-height: 320px;
  margin: 5px;
}
section.section-news .news.news-lg {
  width: 100%;
  flex-direction: row;
  margin: 0 5px 10px 5px;
}
section.section-news .news.news-lg .news-description {
  background-color: transparent;
  width: 50%;
  padding: 0 0 0 40px;
}
section.section-news .news.news-lg .news-description h3 {
  color: #222;
  margin-bottom: 10px;
  font-size: 26px;
}
section.section-news .news.news-lg .news-description p {
  font-size: 15px;
  line-height: 28px;
}
section.section-news .news.news-lg .news-description .date {
  font-size: 13px;
}
section.section-news .news.news-lg .news-description:hover {
  background-color: transparent;
}
section.section-news .news.news-lg .news-description:hover .btn-default span {
  background-color: #ff6c00;
}
section.section-news .news .news-description {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #f5f5f5;
  transition: all 350ms;
  justify-content: space-between;
}
section.section-news .news .news-description h3 {
  font-size: 20px;
}
section.section-news .news .news-description p {
  font-size: 13px;
  line-height: 22px;
  margin-top: 10px;
}
section.section-news .news .news-description .date {
  font-size: 11px;
}
section.section-news .news .news-description:hover {
  background-color: rgba(255, 108, 0, 0.6);
}
section.section-news .news .news-description:hover .btn-default span {
  background-color: #222;
}
section.o-nas-section-content {
  padding-bottom: 140px;
  overflow: hidden;
}
section.o-nas-section-content .heading-element {
  padding: 80px 0 50px 0;
}
section.o-nas-section-content .section-content .section-element {
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
section.o-nas-section-content .section-content .section-element.reverse {
  flex-direction: row-reverse;
}
section.o-nas-section-content .section-content .section-element .text {
  width: 50%;
  text-align: justify;
}
section.o-nas-section-content .section-content .section-element .text h2 {
  font-weight: 600;
  margin-bottom: 20px;
}
section.o-nas-section-content .section-content .section-element .text p {
  margin-top: 10px;
  font-size: 15px;
  line-height: 28px;
}
section.o-nas-section-content .section-content .section-element .img {
  position: relative;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.o-nas-section-content .section-content .section-element .img .color-element {
  width: 35px;
  height: 30px;
  left: 20%;
}
section.o-nas-section-content .section-content .section-element .img .color-element .sm-color-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-150%, 100%);
  background-color: #ff6c00;
  width: 25px;
  height: 22px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
}
section.o-nas-section-content .section-content .section-element .img img {
  width: 140px;
  opacity: 0.7;
}
section.o-nas-section-content .section-team {
  padding-top: 80px;
}
section.o-nas-section-content .section-team h2 {
  font-size: 45px;
  font-weight: 600;
}
section.o-nas-section-content .section-team .team-wrapper {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
}
section.o-nas-section-content .section-team .team-wrapper .person {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.o-nas-section-content .section-team .team-wrapper .person:nth-of-type(even) {
  flex-direction: row-reverse;
}
section.o-nas-section-content .section-team .team-wrapper .person:nth-of-type(even) .person-img:before {
  left: -20%;
  right: 0;
}
section.o-nas-section-content .section-team .team-wrapper .person:nth-of-type(even) .person-description {
  padding: 0 40px 0 0;
}
section.o-nas-section-content .section-team .team-wrapper .person:not(:first-of-type) {
  padding-top: 100px;
}
section.o-nas-section-content .section-team .team-wrapper .person .person-img {
  position: relative;
  width: 30%;
  height: 400px;
}
section.o-nas-section-content .section-team .team-wrapper .person .person-img:before {
  content: "";
  position: absolute;
  top: -20px;
  z-index: -1;
  background-color: #f5f5f5;
  width: 1500px;
  height: 110%;
  right: -20%;
}
section.o-nas-section-content .section-team .team-wrapper .person .person-img img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}
section.o-nas-section-content .section-team .team-wrapper .person .person-description {
  width: 60%;
  padding: 0 0 0 40px;
}
section.o-nas-section-content .section-team .team-wrapper .person .person-description span.name {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 24px;
  display: block;
}
section.o-nas-section-content .section-team .team-wrapper .person .person-description span.name:after {
  display: inline-block;
  transform: translate(5px, -5px);
  content: "";
  width: 15px;
  height: 1px;
  background-color: #222;
}
section.o-nas-section-content .section-team .team-wrapper .person .person-description span.position {
  font-size: 14px;
  display: block;
}
section.o-nas-section-content .section-team .team-wrapper .person .person-description p {
  margin-top: 20px;
  font-size: 15px;
  line-height: 26px;
  text-align: justify;
}
section.o-nas-section-content .section-team .team-wrapper .person .person-description p a {
  color: #ff6c00;
}
section.o-nas-section-content .section-team .team-wrapper .person .person-description .btn-default {
  margin-top: 30px;
}
section.o-nas-section-content .section-carreer {
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
section.o-nas-section-content .section-carreer h3 {
  font-size: 36px;
  line-height: 46px;
  text-align: center;
}
section.o-nas-section-content .section-gallery {
  display: flex;
  align-items: center;
  justify-content: center;
}
section.o-nas-section-content .section-gallery .items-wrapper {
  width: 38%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 10px;
}
section.o-nas-section-content .section-gallery .items-wrapper .item:first-of-type .item-overlay,
section.o-nas-section-content .section-gallery .items-wrapper .item:last-of-type .item-overlay {
  background-color: rgba(255, 108, 0, 0.5);
  opacity: 1;
}
section.o-nas-section-content .section-gallery .items-wrapper .item:first-of-type .item-overlay:hover img,
section.o-nas-section-content .section-gallery .items-wrapper .item:last-of-type .item-overlay:hover img {
  opacity: 1;
}
section.o-nas-section-content .section-gallery .item {
  position: relative;
  width: 49%;
  height: 145px;
  margin-top: 8px;
}
section.o-nas-section-content .section-gallery .item.big {
  width: 60%;
  height: auto;
  padding-top: 0;
}
section.o-nas-section-content .section-gallery .item.big .item-overlay img {
  width: 60px;
  height: 60px;
}
section.o-nas-section-content .section-gallery .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
section.o-nas-section-content .section-gallery .item .item-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 350ms;
}
section.o-nas-section-content .section-gallery .item .item-overlay img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  width: 35px;
  height: 35px;
  opacity: 0;
  transition: opacity 350ms;
}
section.o-nas-section-content .section-gallery .item:hover .item-overlay,
section.o-nas-section-content .section-gallery .item:hover .item-overlay img {
  opacity: 1;
}
section.offer-page-section-content {
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;
}
section.offer-page-section-content .img-absolute {
  position: fixed;
  z-index: -1;
  width: 300px;
  left: -70px;
  bottom: 20%;
}
section.offer-page-section-content .heading-element {
  margin-top: 80px;
}
section.offer-page-section-content .section-cta {
  position: relative;
  display: flex;
  align-items: center;
  margin: 60px 0 40px 0;
}
section.offer-page-section-content .section-cta .color-element {
  width: 35px;
  height: 30px;
  left: 15%;
}
section.offer-page-section-content .section-cta .color-element .sm-color-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-150%, 100%);
  background-color: #ff6c00;
  width: 25px;
  height: 22px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
}
section.offer-page-section-content .section-cta a {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  padding: 0 250px;
  color: #222;
  display: block;
  transition: color 350ms;
  text-align: center;
  width: 100%;
}
section.offer-page-section-content .section-cta a:hover {
  color: #ff6c00;
}
section.offer-page-section-content .section-content .description {
  padding-top: 60px;
}
section.offer-page-section-content .section-content .description h2 {
  font-size: 22px;
  margin-bottom: 20px;
}
section.offer-page-section-content .section-content .description p {
  text-align: justify;
  margin-top: 20px;
  font-size: 15px;
  line-height: 28px;
}
section.offer-page-section-content .section-content .section-element {
  margin-top: 60px;
}
section.offer-page-section-content .section-content .section-element:nth-of-type(even) .section-text h4 {
  text-align: right;
}
section.offer-page-section-content .section-content .section-element .section-text h4 {
  font-size: 36px;
  line-height: 46px;
  margin-bottom: 30px;
  text-align: left;
}
section.offer-page-section-content .section-content .section-element .section-text p {
  text-align: justify;
  font-size: 15px;
  line-height: 28px;
}
section.offer-page-section-content .section-content .section-element .section-text .btn {
  margin-top: 20px;
}
section.offer-page-section-content .section-content .section-element .section-text .elements-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}
section.offer-page-section-content .section-content .section-element .section-text .element {
  position: relative;
  margin: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 32.7%;
  height: 200px;
  padding: 40px 30px;
  background-color: #f5f5f5;
  transition: background-color 350ms;
  cursor: default;
}
section.offer-page-section-content .section-content .section-element .section-text .element span {
  display: block;
  font-weight: 700;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
section.offer-page-section-content .section-content .section-element .section-text .element .number {
  position: absolute;
  left: 0;
  top: -20px;
  font-size: 75px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.3);
}
section.offer-page-section-content .section-content .section-element .section-text .element p {
  text-align: center;
}
section.offer-page-section-content .section-content .section-element .section-text .element:first-of-type,
section.offer-page-section-content .section-content .section-element .section-text .element:last-of-type {
  background-color: rgba(255, 108, 0, 0.6);
}
section.offer-page-section-content .section-content .section-element .section-text .element:first-of-type:hover,
section.offer-page-section-content .section-content .section-element .section-text .element:last-of-type:hover {
  background-color: rgba(255, 108, 0, 0.8);
}
section.offer-page-section-content .section-content .section-element .section-text .element:hover {
  background-color: #ddd;
}
section.aktualnosci-section-content {
  padding-bottom: 100px;
}
section.aktualnosci-section-content .news-wrapper {
  display: flex;
  padding-top: 70px;
}
section.aktualnosci-section-content .news-wrapper .news-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
section.aktualnosci-section-content .news-wrapper .news-content .news-description {
  padding-left: 0;
  margin-left: 50px;
}
section.aktualnosci-section-content .news-wrapper .news-content .news-description h3 {
  font-size: 20px;
  line-height: 30px;
}
section.aktualnosci-section-content .news-wrapper .news-content .news-description h3:after {
  transform: translate(10px, -6px);
}
section.aktualnosci-section-content .news-wrapper .news-content .news-description .btn-default span {
  transform: translate(-7px, -4px);
}
section.aktualnosci-section-content .news-wrapper .news-content .news-item {
  width: 100%;
  display: flex;
}
section.aktualnosci-section-content .news-wrapper .news-content .news-item:not(:first-of-type) {
  margin-top: 30px;
}
section.aktualnosc-section-content {
  padding-bottom: 120px;
}
section.aktualnosc-section-content .section-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 60px;
}
section.aktualnosc-section-content .section-content .description {
  width: 55%;
}
section.aktualnosc-section-content .section-content .description p {
  font-size: 15px;
  line-height: 28px;
}
section.aktualnosc-section-content .section-content .description p:not(:first-of-type) {
  margin-top: 10px;
}
section.aktualnosc-section-content .section-content .description a {
  color: #ff6c00;
}
section.aktualnosc-section-content .section-content .description ul {
  padding: 10px 0 0 17px;
}
section.aktualnosc-section-content .section-content .description ul li {
  position: relative;
  padding: 5px 0;
  font-size: 15px;
}
section.aktualnosc-section-content .section-content .description ul li:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  background: #ff6c00;
  top: 12px;
  left: -15px;
  transition: all 250ms;
}
section.aktualnosc-section-content .section-content .img {
  position: relative;
  width: 40%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
section.aktualnosc-section-content .section-content .img img {
  width: 100%;
  height: 70%;
  object-fit: cover;
}
section.aktualnosc-section-content .section-content .img:hover .color-element {
  width: 120px;
  height: 120px;
}
section.aktualnosc-section-content .section-content .img .btn-default {
  font-size: 18px;
}
section.aktualnosc-section-content .section-content .img .btn-default span {
  transform: translate(-7px, -5px);
}
section.faq-section-content {
  padding-bottom: 120px;
  position: relative;
  overflow: hidden;
}
section.faq-section-content .faq-content {
  padding-top: 60px;
  width: 75%;
}
section.faq-section-content .faq-content h2 {
  margin-bottom: 20px;
}
section.faq-section-content .faq-content .faq-item {
  margin: 0 0 30px 15px;
}
section.faq-section-content .faq-content .faq-item h3 {
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
  transition: all 250ms;
}
section.faq-section-content .faq-content .faq-item h3:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  background: #ff6c00;
  top: 10px;
  left: -15px;
}
section.faq-section-content .faq-content .faq-item p {
  font-size: 15px;
  line-height: 28px;
  text-align: justify;
}
section.faq-section-content .faq-content .faq-item p:not(:first-of-type) {
  margin-top: 10px;
}
section.faq-section-content .faq-content .faq-item:hover span {
  color: #ff6c00;
}
section.faq-section-content .img-absolute {
  position: absolute;
  right: -8%;
  bottom: 30%;
  opacity: 0.3;
  width: 400px;
  height: 400px;
}
section.static-page-section-content {
  padding-bottom: 140px;
}
section.static-page-section-content .heading-element {
  margin-top: 30px;
}
section.static-page-section-content .section-content .description {
  padding-top: 60px;
}
section.static-page-section-content .section-content .description h2 {
  font-size: 22px;
  margin-bottom: 20px;
}
section.static-page-section-content .section-content .description h3 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
}
section.static-page-section-content .section-content .description p {
  text-align: justify;
  margin-top: 20px;
  font-size: 15px;
  line-height: 28px;
}
section.static-page-section-content .section-content .description ul {
  padding: 20px 0 0 17px;
}
section.static-page-section-content .section-content .description ul li {
  position: relative;
  padding: 5px 0;
  font-size: 15px;
  line-height: 28px;
}
section.static-page-section-content .section-content .description ul li:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  background: #ff6c00;
  top: 12px;
  left: -15px;
  transition: all 250ms;
}
section.static-page-section-content .section-content .description .element-image {
  width: 100%;
  padding: 20px 0;
}
section.static-page-section-content .section-content .description .element-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
section.static-page-section-content .section-content .description .btn-default {
  font-size: 15px;
}
section.static-page-section-content .section-content .description .btn-default span {
  transform: translate(-7px, -6px);
}
section.static-page-section-content .owl-carousel-wrapper {
  background-color: #f5f5f5;
  height: 180px;
  padding-top: 30px;
  margin-top: 50px;
  display: flex;
  align-items: center;
}
section.static-page-section-content .owl-carousel-wrapper .owl-carousel-partners {
  width: 70%;
  margin: 0 auto;
}
section.static-page-section-content .section-cooperation {
  padding-top: 60px;
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
}
section.static-page-section-content .section-cooperation .section-text {
  width: 45%;
}
section.static-page-section-content .section-cooperation .section-text h3 {
  margin-bottom: 20px;
  font-size: 17px;
  line-height: 34px;
}
section.static-page-section-content .section-cooperation .section-text h3 strong {
  color: #ff6c00;
  font-size: 34px;
}
section.static-page-section-content .section-cooperation .section-text p {
  font-size: 15px;
  text-align: justify;
}
section.static-page-section-content .section-cooperation .section-text p:not(:first-of-type) {
  margin-top: 10px;
}
section.static-page-section-content .section-cooperation .section-text ul {
  padding: 10px 0 0 20px;
}
section.static-page-section-content .section-cooperation .section-text ul li {
  padding: 3px 0;
}
section.static-page-section-content .section-cooperation .section-text .link-color {
  margin: 40px 0 0 0;
}
section.kontakt-section-content {
  padding-bottom: 80px;
}
section.kontakt-section-content .section-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
section.kontakt-section-content .section-wrapper h2 {
  font-size: 28px;
  line-height: 40px;
}
section.kontakt-section-content .section-wrapper h2 strong {
  color: #ff6c00;
  font-size: 34px;
}
section.kontakt-section-content .section-wrapper h3 {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 20px;
}
section.kontakt-section-content .section-wrapper .section-information {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
}
section.kontakt-section-content .section-wrapper .section-information .information-column {
  width: 50%;
}
section.kontakt-section-content .section-wrapper .section-information .information-column address {
  font-size: 15px;
}
section.kontakt-section-content .section-wrapper .section-information .information-column a {
  color: #222;
  font-weight: 600;
  font-size: 15px;
  transition: color 350ms;
  margin-left: 5px;
}
section.kontakt-section-content .section-wrapper .section-information .information-column a span {
  font-size: 13.5px;
  font-weight: normal;
}
section.kontakt-section-content .section-wrapper .section-information .information-column a:hover {
  color: #ff6c00;
}
section.kontakt-section-content .section-wrapper .section-information .information-column p {
  margin-top: 10px;
}
section.kontakt-section-content .section-wrapper .section-information .information-column:nth-of-type(2) {
  margin-top: 5px;
}
section.kontakt-section-content .section-wrapper .section-map {
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
}
section.kontakt-section-content .section-wrapper .section-map .map-wrapper {
  width: 48%;
}
section.kontakt-section-content .section-wrapper .section-map .map-wrapper iframe {
  height: 100%;
  width: 100%;
}
section.kontakt-section-content .section-wrapper .section-map .section-form {
  padding: 20px 30px;
  margin: 0;
  width: 50%;
}
section.kontakt-section-content .section-wrapper .section-map .section-form h3 {
  margin-bottom: 0;
}
section.kontakt-section-content .section-wrapper .heading {
  padding-top: 20px;
}
section.kontakt-section-content .section-wrapper .section-team {
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
section.kontakt-section-content .section-wrapper .section-team .person {
  width: 18%;
  margin: 8px;
  display: flex;
  flex-direction: column;
}
section.kontakt-section-content .section-wrapper .section-team .person .image {
  height: 60%;
  width: 100%;
}
section.kontakt-section-content .section-wrapper .section-team .person .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
section.kontakt-section-content .section-wrapper .section-team .person .contact {
  height: 35%;
  margin-top: 10px;
}
section.kontakt-section-content .section-wrapper .section-team .person .contact .name {
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
}
section.kontakt-section-content .section-wrapper .section-team .person .contact .name:after {
  display: inline-block;
  transform: translate(5px, -5px);
  content: "";
  width: 15px;
  height: 1px;
  background-color: #000;
}
section.kontakt-section-content .section-wrapper .section-team .person .contact .position {
  font-size: 15px;
  margin-bottom: 10px;
  display: block;
}
section.kontakt-section-content .section-wrapper .section-team .person .contact a {
  color: #222;
  font-weight: 600;
  font-size: 14.5px;
  transition: color 350ms;
}
section.kontakt-section-content .section-wrapper .section-team .person .contact a span {
  font-size: 13px;
  font-weight: normal;
}
section.kontakt-section-content .section-wrapper .section-team .person .contact a:hover {
  color: #ff6c00;
}
.btn {
  font-size: 15px;
  letter-spacing: 0.5px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  transition: all 350ms;
}
.btn.btn-color {
  background-color: #ff6c00;
  color: #fff;
  max-width: 250px;
  margin: 0;
}
.btn.btn-pulse {
  box-shadow: 0 0 0 0 #ff6c00;
  animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
}
.btn.btn-pulse:hover {
  background-color: #b34c00;
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}
.btn:hover.btn-color {
  background-color: #e66100;
}
nav.faktoring-section-header {
  position: relative;
  z-index: 22;
  background-color: #ffff;
  transition: all 350ms;
}
nav.faktoring-section-header.fixed {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  animation: fixed-nav 550ms ease;
}
@keyframes fixed-nav {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}
nav.faktoring-section-header .header-flexbox {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.faktoring-section-header .header-flexbox .flexbox-logo img {
  width: 150px;
}
nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.color-link a {
  color: #ff6c00;
  font-weight: 700;
}
nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  color: #000;
  opacity: 0.8;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.5px;
  transition: all 350ms;
}
nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a.btn-color-1 {
  color: #fff;
}
nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:hover a {
  color: #ff6c00;
}
nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:hover a.btn-color-1 {
  color: #fff;
}
nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:not(:last-of-type) {
  margin-right: 40px;
}
nav.faktoring-section-header .header-flexbox .nav-button {
  padding: 10px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
}
nav.faktoring-section-header .header-flexbox .nav-button .button-bar {
  height: 2px;
  width: 24px;
  background: #000;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.faktoring-section-header .header-flexbox .nav-button .button-bar:nth-child(2) {
  margin-top: 3.5px;
  width: 27px;
}
nav.faktoring-section-header .header-flexbox .nav-button .button-bar:nth-child(3) {
  margin-top: 3.5px;
  width: 30px;
}
nav.faktoring-section-header .header-flexbox .nav-button:hover .button-bar,
nav.faktoring-section-header .header-flexbox .nav-button:focus .button-bar {
  width: 30px !important;
}
.faktoring-section-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 15px;
}
section.faktoring-section-welcome {
  position: relative;
  background-color: #f8f8fa;
  padding: 50px 0;
}
section.faktoring-section-welcome .blob-bg {
  position: absolute;
  width: 1500px;
  top: 50%;
  left: 50%;
  transform: translate(-95%, -50%);
}
section.faktoring-section-welcome .welcome-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
section.faktoring-section-welcome .faktoring-wrapper-form {
  scroll-margin-top: 200px;
  width: 91%;
  margin: 0 auto;
}
section.faktoring-section-welcome .faktoring-wrapper-form.hide {
  display: none;
}
section.faktoring-section-welcome .wrapper-text {
  position: relative;
  z-index: 1;
  width: 65%;
  padding-left: 50px;
}
section.faktoring-section-welcome .wrapper-text .text-content {
  transition: all 1000ms;
}
section.faktoring-section-welcome .wrapper-text .text-content.hide {
  opacity: 0;
  transform: translateX(-80px);
}
section.faktoring-section-welcome .wrapper-text h2 {
  font-size: 90px;
  line-height: 80px;
  font-weight: 800;
  margin-bottom: 20px;
  color: #ff6c00;
}
section.faktoring-section-welcome .wrapper-text h3 {
  font-size: 45px;
  line-height: 50px;
  font-weight: 600;
}
section.faktoring-section-welcome .wrapper-text h3 span {
  color: #ff6c00;
}
section.faktoring-section-welcome .wrapper-text .element {
  transition: all 1000ms;
  position: relative;
  padding: 5px 0 5px 80px;
}
section.faktoring-section-welcome .wrapper-text .element:before {
  position: absolute;
  top: 11.5px;
  left: 38px;
  z-index: 1;
  content: "";
  display: inline-block;
  background: url("../img/icons/checked.svg") no-repeat;
  background-size: 15px 15px;
  width: 15px;
  height: 15px;
}
section.faktoring-section-welcome .wrapper-text .element:after {
  position: absolute;
  z-index: -1;
  top: 8px;
  left: 35px;
  background-color: #ff6c00;
  content: "";
  width: 22px;
  height: 22px;
  display: inline-block;
}
section.faktoring-section-welcome .wrapper-text .element.hide {
  opacity: 0;
  transform: translateX(-80px);
}
section.faktoring-section-welcome .wrapper-text p {
  font-size: 18px;
  letter-spacing: 0.5px;
  opacity: 0.8;
  font-weight: 600;
}
section.faktoring-section-welcome .wrapper-text .btn-color {
  margin: 30px 0 0 35px;
  transition: all 1000ms;
}
section.faktoring-section-welcome .wrapper-text .btn-color.hide {
  opacity: 0;
  transform: translateX(-80px);
}
section.faktoring-section-welcome .wrapper-image {
  position: relative;
  width: 30%;
  transition: all 1s;
  height: 65vh;
}
section.faktoring-section-welcome .wrapper-image img {
  max-width: 400px;
}
section.faktoring-section-about {
  scroll-margin-top: 60px;
  padding-top: 100px;
  background-color: #f8f8fa;
  overflow: hidden;
}
section.faktoring-section-about.company {
  padding: 60px 0;
  background-color: #fff;
}
section.faktoring-section-about.company .wrapper-content {
  display: flex;
  flex-direction: column;
  text-align: center;
}
section.faktoring-section-about.company .wrapper-content header {
  width: 100%;
  padding: 0;
}
section.faktoring-section-about.company .wrapper-content p {
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.5px;
  opacity: 0.8;
  font-weight: 300;
  max-width: 1000px;
  margin: 0 auto;
}
section.faktoring-section-about.company .wrapper-content p:not(:first-of-type) {
  margin: 15px auto 0;
}
section.faktoring-section-about.company .wrapper-content .btn-color {
  margin-top: 40px;
}
section.faktoring-section-about .wrapper-content {
  display: flex;
  align-items: center;
}
section.faktoring-section-about .wrapper-content header {
  width: 45%;
  padding-right: 50px;
}
section.faktoring-section-about .wrapper-content header p {
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.5px;
  opacity: 0.8;
  font-weight: 300;
}
section.faktoring-section-about .wrapper-content header p:not(:first-of-type) {
  margin-top: 15px;
}
section.faktoring-section-about .wrapper-content header h2 {
  margin-bottom: 30px;
  font-size: 38px;
  font-weight: 600;
}
section.faktoring-section-about .wrapper-content header h2 strong {
  color: #ff6c00;
}
section.faktoring-section-about .wrapper-content .content-description {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 55%;
  gap: 20px;
}
section.faktoring-section-about .wrapper-content .content-description .box-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  text-align: center;
  padding: 25px;
  background-color: #ffffff;
  box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.05);
}
section.faktoring-section-about .wrapper-content .content-description .box-inner .icon {
  background-color: #ff6c00;
  padding: 10px;
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}
section.faktoring-section-about .wrapper-content .content-description .box-inner .icon img {
  width: 30px;
}
section.faktoring-section-about .wrapper-content .content-description .box-inner h3 {
  font-size: 15px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-weight: 600;
}
section.faktoring-section-about .wrapper-content .content-description .box-inner p {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  opacity: 0.8;
  font-weight: 300;
}
section.faktoring-section-clients {
  padding-top: 100px;
  background: linear-gradient(180deg, #f8f8f8 5%, rgba(243, 245, 245, 0) 100%);
}
section.faktoring-section-clients header {
  text-align: center;
}
section.faktoring-section-clients header h2 {
  margin-bottom: 30px;
  font-size: 38px;
  font-weight: 600;
}
section.faktoring-section-clients header h2 strong {
  color: #ff6c00;
}
section.faktoring-section-clients .wrapper-content {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
section.faktoring-section-clients .wrapper-content .content-element {
  text-align: center;
  width: calc(100% / 4);
}
section.faktoring-section-clients .wrapper-content .content-element img {
  width: 50px;
  margin-bottom: 10px;
}
section.faktoring-section-clients .wrapper-content .content-element h3 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
}
section.faktoring-section-clients .wrapper-content .content-element p {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
  opacity: 0.8;
  font-weight: 300;
}
section.faktoring-section-advantages {
  padding: 100px 0;
  background-color: #ffff;
}
section.faktoring-section-advantages header {
  text-align: center;
}
section.faktoring-section-advantages header h2 {
  margin-bottom: 30px;
  font-size: 38px;
}
section.faktoring-section-advantages header h2 strong {
  color: #ff6c00;
}
section.faktoring-section-advantages .wrapper-content {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}
section.faktoring-section-advantages .wrapper-content .content-text {
  width: 50%;
}
section.faktoring-section-advantages .wrapper-content .content-text ul li {
  position: relative;
  padding: 15px 0 15px 50px;
  line-height: 30px;
  opacity: 0.8;
  font-weight: 300;
}
section.faktoring-section-advantages .wrapper-content .content-text ul li strong {
  display: block;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 600;
}
section.faktoring-section-advantages .wrapper-content .content-text ul li:after {
  position: absolute;
  left: 0;
  top: 18px;
  background-color: #ff6c00;
  content: "";
  width: 25px;
  height: 25px;
  display: inline-block;
}
section.faktoring-section-advantages .wrapper-content .content-text ul li:before {
  position: absolute;
  z-index: 1;
  left: 4px;
  top: 18px;
  content: url(../img/icons/checked.svg);
  background-size: 16px 16px;
  display: inline-block;
  width: 16px;
  height: 16px;
}
section.faktoring-section-advantages .wrapper-content .content-text .btn-color {
  margin-top: 30px;
}
section.faktoring-section-advantages .wrapper-content .content-images {
  width: 42%;
}
section.faktoring-section-advantages .wrapper-content .content-images img {
  position: sticky;
  top: 100px;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  max-height: 300px;
  object-fit: cover;
  box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.1);
}
section.faktoring-section-testimonials {
  padding: 40px 0 60px;
  background-color: #f8f8f8;
}
section.faktoring-section-testimonials .owl-carousel-testimonials {
  width: 100%;
}
section.faktoring-section-testimonials .owl-carousel-testimonials .owl-stage-outer,
section.faktoring-section-testimonials .owl-carousel-testimonials .owl-stage,
section.faktoring-section-testimonials .owl-carousel-testimonials .owl-item {
  height: 100%;
}
section.faktoring-section-testimonials .owl-carousel-testimonials .owl-item {
  transition: all 0.8s;
  opacity: 0.25;
  transform: scale(0.85);
}
section.faktoring-section-testimonials .owl-carousel-testimonials .owl-item .item {
  padding: 50px 20px 10px;
  text-align: center;
}
section.faktoring-section-testimonials .owl-carousel-testimonials .owl-item .item header {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
}
section.faktoring-section-testimonials .owl-carousel-testimonials .owl-item .item header:before {
  position: absolute;
  z-index: -1;
  content: "";
  width: 50px;
  height: 50px;
  background-color: #ff6c00;
  top: -25%;
  right: 0;
}
section.faktoring-section-testimonials .owl-carousel-testimonials .owl-item .item header h2 {
  font-size: 22px;
  margin: 0;
}
section.faktoring-section-testimonials .owl-carousel-testimonials .owl-item .item header span {
  display: block;
  margin-top: 10px;
  font-size: 14px;
}
section.faktoring-section-testimonials .owl-carousel-testimonials .owl-item .item p {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
  opacity: 0.8;
  font-weight: 300;
}
section.faktoring-section-testimonials .owl-carousel-testimonials .owl-item.center {
  opacity: 1;
  transform: scale(1.05);
}
section.faktoring-section-testimonials .owl-carousel-testimonials .owl-dots {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: none;
}
section.faktoring-section-testimonials .owl-carousel-testimonials .owl-dots .owl-dot {
  margin: 5px;
  height: 3px;
  width: 20px;
  border-radius: 0;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  transition: all 0.4s;
  outline: none;
}
section.faktoring-section-testimonials .owl-carousel-testimonials .owl-dots .owl-dot span {
  opacity: 0;
}
section.faktoring-section-testimonials .owl-carousel-testimonials .owl-dots .owl-dot.active {
  background-color: #ff6c00;
  width: 40px;
}
section.faktoring-section-testimonials .owl-carousel-testimonials .owl-dots .owl-dot.active span {
  background-color: #000;
}
.faktoring-wrapper-form {
  position: relative;
  background-color: #000;
  padding: 20px 20px 0;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
.faktoring-wrapper-form.hide {
  display: none;
}
.faktoring-wrapper-form header {
  text-align: center;
  color: #fff;
}
.faktoring-wrapper-form header h3 {
  margin-bottom: 10px;
  font-size: 22px;
}
.faktoring-wrapper-form header p {
  color: #fff;
  margin-bottom: 10px;
}
.faktoring-wrapper-form .form-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}
.faktoring-wrapper-form .form-inner .input-service {
  width: calc(100% / 4 + 30px);
}
.faktoring-wrapper-form input,
.faktoring-wrapper-form textarea {
  background-color: #f8f8fa;
}
.faktoring-wrapper-form textarea,
.faktoring-wrapper-form input {
  transition: all 0.2s;
  border: 1px solid #ff6c00;
  border-radius: 0;
  height: 45px;
  margin: 8px 0;
  width: 100%;
}
.faktoring-wrapper-form textarea:focus,
.faktoring-wrapper-form input:focus {
  outline: none;
  border: 1px solid #ff6c00;
}
.faktoring-wrapper-form input::placeholder {
  font-size: 13px;
  text-align: center;
}
.faktoring-wrapper-form textarea {
  height: 100px;
}
.faktoring-wrapper-form .checkbox-wrapper .radio-box span {
  color: #fff;
}
.checkbox-wrapper {
  position: relative;
  cursor: pointer;
  display: block;
  margin: 10px 0 0;
  height: 35px;
  text-align: center;
}
.checkbox-wrapper .radio-box {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-transform: none;
  color: #fff;
  text-align: left;
  transition: all 0.3s;
  padding: 0;
  font-weight: 500;
  width: 100%;
}
.checkbox-wrapper .radio-box:focus {
  border: none;
}
.checkbox-wrapper .radio-box:before {
  content: "";
  min-width: 10px;
  max-width: 10px;
  height: 10px;
  display: block;
  background: url("../img/icons/checkbox.svg") no-repeat;
  margin-right: 10px;
  transition: all 0.3s;
}
.checkbox-wrapper .radio-box span {
  font-size: 11px;
  width: 100%;
  display: block;
}
.checkbox-wrapper input {
  width: auto !important;
  height: auto;
  position: absolute;
  left: 0;
  opacity: 0;
}
.checkbox-wrapper input:focus {
  box-shadow: none;
}
.checkbox-wrapper > input:checked + .radio-box {
  font-weight: 700;
}
.checkbox-wrapper > input:checked + .radio-box:before {
  background: url("../img/icons/checkbox-checked.svg") no-repeat;
}
section.faktoring-section-footer {
  padding-top: 20px;
  background-color: #000;
  text-align: center;
}
section.faktoring-section-footer .logo {
  width: 200px;
}
section.faktoring-section-footer .faktoring-wrapper-form {
  margin-top: 20px;
  background: #222;
}
section.faktoring-section-footer .footer-flexbox {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.faktoring-section-footer .footer-inner {
  display: flex;
  align-items: center;
}
section.faktoring-section-footer .footer-inner ul {
  display: flex;
}
section.faktoring-section-footer .footer-inner h2 {
  font-size: 14px;
  color: #fff;
}
section.faktoring-section-footer .footer-inner a {
  display: block;
  font-size: 14px;
  color: #fff;
  margin-left: 30px;
}
section.section-footer {
  background-color: #404040;
  position: relative;
  z-index: 444;
}
section.section-footer .service-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translatey(-30px);
}
section.section-footer .service-logo img {
  height: 70px;
}
section.section-footer .service-logo p {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 700;
  margin-top: 10px;
}
section.section-footer .footer-flexbox {
  position: relative;
  display: flex;
  justify-content: space-between;
}
section.section-footer .footer-flexbox .flexbox-service {
  margin-top: 20px;
}
section.section-footer .footer-flexbox .flexbox-service .heading {
  margin-top: 0;
  padding-bottom: 10px;
  font-size: 18px;
  color: #fff;
}
section.section-footer .footer-flexbox .flexbox-service ul li {
  margin-top: 5px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a {
  font-size: 14px;
  color: #fff;
  transition: color 350ms;
}
section.section-footer .footer-flexbox .flexbox-service ul li a span {
  color: #fff;
}
section.section-footer .footer-flexbox .flexbox-service ul li a:hover {
  color: #ff6c00;
}
section.section-footer .footer-flexbox .flexbox-service ul li a:hover span {
  color: #fff;
}
section.section-footer .footer-flexbox .flexbox-service .list-social {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li {
  margin-right: 10px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li a img {
  height: 25px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li:last-child {
  margin-right: 0;
}
section.section-footer .alert {
  position: relative;
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  width: 90%;
  margin: 50px auto 0 auto;
}
section.section-footer .alert p {
  font-size: 14px;
  color: #fff;
  font-weight: 300;
  text-align: center;
}
section.section-footer .alert .alert-links {
  margin-top: 10px;
  text-align: center;
}
section.section-footer .alert .alert-links a {
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 500;
}
section.section-footer .alert .alert-links a:hover {
  color: #ff6c00;
}
section.section-footer .footer-credits-wrapper {
  margin-top: 40px;
  padding: 25px 0;
  background: #f5f5f5;
}
section.section-footer .footer-credits-wrapper .footer-credits {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
section.section-footer .footer-credits-wrapper .footer-credits p {
  font-size: 13px;
  color: #444;
  font-weight: 300;
}
section.section-footer .footer-credits-wrapper .footer-credits p a {
  color: #ff6c00;
  font-weight: 600;
}
@media (max-width: 1410.98px) {
  section.offer-page-section-content .img-absolute {
    width: 200px;
    left: -100px;
  }
  section.section-footer .footer-flexbox {
    padding-top: 20px;
  }
}
@media (max-width: 1280.98px) {
  section.section-welcome .welcome-background .background-right-col {
    padding-right: 20px;
  }
}
@media (max-width: 1190.98px) {
  .section-heading {
    display: none;
  }
  nav.section-header .header-flexbox {
    padding: 20px 0;
  }
  nav.section-header .header-flexbox .flexbox-logo img {
    height: 45px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    padding: 0 15px;
  }
  section.section-welcome .welcome-background .owl-carousel-wrapper {
    width: 400px;
  }
  section.section-welcome .welcome-background .owl-carousel-wrapper .owl-carousel-welcome .owl-controls {
    transform: translate(190%, -50%);
  }
  section.section-welcome .welcome-background .owl-carousel-wrapper .owl-carousel-welcome .owl-item-wrapper h2 {
    font-size: 28px;
  }
  section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .description {
    padding: 0;
    width: 35%;
  }
  section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .heading {
    width: 40%;
  }
  section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-dots {
    right: 30px;
  }
  section.section-team .section-wrapper .person {
    width: 49.2%;
  }
  section.offer-page-section-content .img-absolute {
    width: 200px;
  }
  section.offer-page-section-content .section-content .section-element .section-text .element {
    width: 49.2%;
    height: 200px;
  }
  section.offer-page-section-content .section-cta a {
    font-size: 22px;
  }
  section.faktoring-section-welcome .faktoring-wrapper-form {
    margin-top: 30px;
    width: 100%;
  }
  section.faktoring-section-welcome .wrapper-text {
    width: 100%;
    padding-left: 0;
  }
  section.faktoring-section-welcome .wrapper-text .text-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  section.faktoring-section-welcome .wrapper-text h2,
  section.faktoring-section-welcome .wrapper-text h3 {
    text-align: center;
  }
  section.faktoring-section-welcome .wrapper-text .content-elements {
    padding-left: 70px;
  }
  section.faktoring-section-welcome .wrapper-image {
    display: none;
  }
}
@media (max-width: 992.98px) {
  .link-color {
    font-size: 12px;
    width: 220px;
    height: 70px;
  }
  .news-wrapper .navigation {
    width: 90%;
    margin-bottom: 20px;
  }
  .news-wrapper .navigation p {
    text-align: center;
    font-weight: 600;
  }
  .news-wrapper .navigation ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .news-wrapper .navigation ul li {
    padding: 10px 20px;
  }
  .news-wrapper .news-content {
    width: 100%;
  }
  nav.section-header .header-flexbox {
    justify-content: space-between;
  }
  nav.section-header .header-flexbox .flexbox-logo {
    width: 20%;
  }
  nav.section-header .header-flexbox .flexbox-contact {
    display: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-page {
    width: 80%;
  }
  nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    font-size: 13px;
  }
  nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:not(:last-of-type) {
    margin-right: 13px;
  }
  section.section-welcome .welcome-background .owl-carousel-wrapper .owl-carousel-welcome .owl-controls {
    top: 110%;
    left: 35%;
    transform: translate(0);
  }
  section.section-welcome .welcome-background .owl-carousel-wrapper .owl-carousel-welcome .owl-controls .owl-dots {
    transform: rotate(0);
  }
  section.section-about .section-wrapper {
    flex-direction: column;
  }
  section.section-about .section-wrapper .about-description {
    width: 100%;
  }
  section.section-about .section-wrapper .about-image {
    margin-top: 50px;
    width: 70%;
  }
  section.section-offer {
    height: 800px;
  }
  section.section-offer .section-background {
    flex-direction: column;
  }
  section.section-offer .section-background .background-left-col,
  section.section-offer .section-background .background-right-col {
    width: 100%;
    height: 38%;
  }
  section.section-offer .section-background .owl-carousel-wrapper {
    height: 85%;
  }
  section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .description {
    padding: 0;
    margin: 30px 0 20px 0;
    width: 70%;
  }
  section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .heading {
    width: 60%;
  }
  section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-dots {
    bottom: 520px;
  }
  section.section-team .section-wrapper .person {
    width: 49%;
  }
  section.section-news {
    padding: 60px 0 100px 0;
  }
  section.section-news .news {
    width: 48%;
  }
  section.o-nas-section-content .section-content .section-gallery {
    flex-direction: column;
  }
  section.o-nas-section-content .section-content .section-gallery .item.big {
    width: 80%;
  }
  section.o-nas-section-content .section-content .section-gallery .items-wrapper {
    width: 80%;
    margin-left: 0;
  }
  section.o-nas-section-content .section-team .team-wrapper .person .person-img {
    width: 42%;
  }
  section.o-nas-section-content .section-team .team-wrapper .person .person-description {
    width: 50%;
  }
  section.o-nas-section-content .section-carreer h3 {
    font-size: 28px;
  }
  section.offer-page-section-content .img-absolute {
    width: 150px;
  }
  section.offer-page-section-content .section-content .section-element .section-text .element {
    width: 49%;
  }
  section.offer-page-section-content .section-cta .color-element {
    left: 3%;
  }
  section.offer-page-section-content .section-cta a {
    font-size: 22px;
    padding: 0 100px;
  }
  section.kontakt-section-content .section-wrapper .section-team .person {
    width: 31%;
  }
  section.kontakt-section-content .section-wrapper .section-map {
    flex-direction: column;
  }
  section.kontakt-section-content .section-wrapper .section-map .map-wrapper {
    width: 100%;
    order: 1;
    margin-bottom: 20px;
  }
  section.kontakt-section-content .section-wrapper .section-map .section-form {
    width: 100%;
    order: 2;
  }
  section.faktoring-section-about {
    padding-top: 60px;
  }
  section.faktoring-section-about .wrapper-content {
    flex-direction: column;
  }
  section.faktoring-section-about .wrapper-content header {
    width: 100%;
    padding-right: 0;
  }
  section.faktoring-section-about .wrapper-content .content-description {
    margin-top: 40px;
    width: 100%;
  }
}
@media (max-width: 790.98px) {
  .section-form {
    width: 100%;
    margin: 60px 0 0;
  }
  .page-heading {
    text-align: center;
    padding: 40px 0;
  }
  .page-heading h1 {
    font-size: 50px;
  }
  .page-heading p {
    width: 95%;
    font-size: 16px;
  }
  .pagination-nav {
    padding: 40px 0 0 0;
  }
  .nav-button {
    display: flex;
  }
  .nav-button#nav-button-welcome.hidden {
    display: flex;
  }
  .nav-button.hidden {
    display: flex;
  }
  .nav-button.hidden#nav-button-page .button-bar {
    background-color: #000;
  }
  .nav-inner .nav-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .nav-inner .nav-wrapper nav.section-header .header-flexbox .flexbox-nav-wrapper.nav-welcome {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
  }
  nav.section-header {
    width: 100%;
  }
  nav.section-header.hidden {
    display: block;
    width: 100%;
    height: 100%;
    visibility: visible;
    opacity: 1;
  }
  nav.section-header.bg-color-light {
    background-color: rgba(255, 108, 0, 0.7);
  }
  nav.section-header.bg-color-light .header-flexbox .flexbox-logo {
    width: auto;
  }
  nav.section-header.bg-color-light .header-flexbox .flexbox-logo.hidden {
    display: block;
  }
  nav.section-header.bg-color-light .header-flexbox .flexbox-logo img {
    height: 35px;
  }
  nav.section-header.bg-color-light .header-flexbox .list-contact {
    display: flex;
  }
  nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper.nav-page {
    width: 100%;
  }
  nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper.nav-page.active {
    opacity: 1;
    visibility: visible;
  }
  nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: flex;
    justify-content: flex-end;
  }
  nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    height: 100%;
  }
  nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    padding: 10px 0;
  }
  nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.mobile {
    display: flex;
  }
  nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop {
    display: none;
  }
  nav.section-header.bg-color-light .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    font-size: 16px;
  }
  nav.faktoring-section-header .header-flexbox .nav-button {
    display: flex;
    margin-right: -10px;
  }
  nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: block;
  }
  nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 50px;
    margin-right: 0;
  }
  nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    padding: 5px 0;
  }
  nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    font-size: 15px;
  }
  nav.faktoring-section-header .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  section.section-welcome .welcome-background .owl-carousel-wrapper {
    width: 400px;
    height: 250px;
    transform: translate(-25%, -50%);
  }
  section.section-welcome .welcome-background .owl-carousel-wrapper .owl-element {
    width: 250px;
    top: 78px;
    left: -170px;
  }
  section.section-welcome .welcome-background .background-left-col {
    width: 34%;
  }
  section.section-welcome .welcome-background .background-left-col .social-media span {
    font-size: 12px;
  }
  section.section-welcome .welcome-background .background-left-col .social-media img {
    width: 40px;
  }
  section.section-welcome .welcome-background .background-right-col {
    width: 66%;
  }
  section.section-welcome .welcome-background .background-right-col h3 {
    font-size: 12px;
    margin: 0 0 42px 20px;
    text-align: right;
  }
  section.section-offer {
    height: 100%;
  }
  section.section-offer .section-background {
    padding: 50px 0 30px 0;
    height: auto;
  }
  section.section-offer .section-background .owl-carousel-wrapper {
    position: static;
    top: 0;
    left: 0;
    transform: translate(0);
  }
  section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-dots {
    display: none !important;
  }
  section.section-offer .section-background .background-left-col,
  section.section-offer .section-background .background-right-col {
    display: none;
  }
  section.section-team .section-wrapper .person {
    height: 200px;
    width: 48.5%;
  }
  section.section-team .section-wrapper .person .person-description .description {
    font-size: 12px;
    line-height: 16px;
  }
  section.section-team .section-wrapper .person:hover .person-description {
    height: 90%;
  }
  section.o-nas-section-content {
    padding-bottom: 100px;
  }
  section.o-nas-section-content .section-content .heading-element {
    padding: 50px 0 20px 0;
  }
  section.o-nas-section-content .section-content .section-element,
  section.o-nas-section-content .section-content .section-element.reverse {
    flex-direction: column;
    padding-top: 0;
  }
  section.o-nas-section-content .section-content .section-element .text,
  section.o-nas-section-content .section-content .section-element.reverse .text {
    width: 100%;
  }
  section.o-nas-section-content .section-content .section-element .img,
  section.o-nas-section-content .section-content .section-element.reverse .img {
    margin-top: 50px;
  }
  section.o-nas-section-content .section-content .section-element .img img,
  section.o-nas-section-content .section-content .section-element.reverse .img img {
    width: 120px;
  }
  section.o-nas-section-content .section-team .team-wrapper .person,
  section.o-nas-section-content .section-team .team-wrapper .person:nth-of-type(even),
  section.o-nas-section-content .section-team .team-wrapper .person:not(:first-of-type) {
    flex-direction: column;
    padding-top: 50px;
  }
  section.o-nas-section-content .section-team .team-wrapper .person .person-img,
  section.o-nas-section-content .section-team .team-wrapper .person:nth-of-type(even) .person-img,
  section.o-nas-section-content .section-team .team-wrapper .person:not(:first-of-type) .person-img {
    width: 60%;
  }
  section.o-nas-section-content .section-team .team-wrapper .person .person-description,
  section.o-nas-section-content .section-team .team-wrapper .person:nth-of-type(even) .person-description,
  section.o-nas-section-content .section-team .team-wrapper .person:not(:first-of-type) .person-description {
    margin-top: 50px;
    width: 100%;
  }
  section.o-nas-section-content .section-carreer h3 {
    font-size: 24px;
    line-height: 32px;
  }
  section.offer-page-section-content .img-absolute {
    width: 120px;
  }
  section.offer-page-section-content .section-content .section-element .section-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  section.offer-page-section-content .section-content .section-element .section-text .elements-wrapper {
    flex-direction: column;
    align-items: center;
  }
  section.offer-page-section-content .section-content .section-element .section-text .elements-wrapper .element {
    width: 70%;
    text-align: center;
  }
  section.offer-page-section-content .section-cta .color-element {
    left: -3%;
  }
  section.offer-page-section-content .section-cta a {
    font-size: 18px;
    padding: 0 50px;
  }
  section.section-news .news {
    width: 100%;
    margin: 0 0 8px 0;
  }
  section.section-news .news.news-lg {
    flex-direction: column;
    margin: 0 0 8px 0;
  }
  section.section-news .news.news-lg .news-img {
    display: none;
  }
  section.section-news .news.news-lg .news-description {
    width: 100%;
    padding: 20px;
    background-color: #f5f5f5;
  }
  section.section-news .news.news-lg .news-description h3 {
    font-size: 20px;
  }
  section.section-news .news-wrapper {
    flex-direction: column;
  }
  section.section-news .news-wrapper .navigation {
    width: 100%;
  }
  section.aktualnosci-section-content {
    padding-bottom: 80px;
  }
  section.aktualnosci-section-content .news-wrapper {
    padding-top: 40px;
  }
  section.aktualnosci-section-content .news-wrapper .news-content .news-item {
    flex-direction: column;
    align-items: center;
  }
  section.aktualnosci-section-content .news-wrapper .news-content .news-item .news-img {
    width: 90%;
  }
  section.aktualnosci-section-content .news-wrapper .news-content .news-item .news-description {
    margin-top: 20px;
    width: 90%;
    margin-left: 0;
  }
  section.aktualnosc-section-content .section-content {
    flex-direction: column;
    align-items: center;
  }
  section.aktualnosc-section-content .section-content .img {
    width: 80%;
    height: 100%;
    justify-content: center;
  }
  section.aktualnosc-section-content .section-content .img .btn-default {
    align-self: center;
    font-size: 14px;
  }
  section.aktualnosc-section-content .section-content .description {
    width: 100%;
    margin-top: 20px;
  }
  section.faq-section-content .img-absolute {
    right: -20%;
  }
  section.static-page-section-content {
    padding-bottom: 100px;
  }
  section.static-page-section-content .section-cooperation {
    flex-direction: column;
  }
  section.static-page-section-content .section-cooperation .section-text {
    width: 100%;
  }
  section.static-page-section-content .section-cooperation .section-contact {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  section.static-page-section-content .section-cooperation .section-contact .link-color {
    margin-left: 0;
  }
  section.kontakt-section-content .section-wrapper .section-team {
    justify-content: space-between;
  }
  section.kontakt-section-content .section-wrapper .section-team .person {
    width: 45%;
  }
  section.faktoring-section-clients {
    padding-top: 60px;
  }
  section.faktoring-section-clients .wrapper-content {
    flex-direction: column;
  }
  section.faktoring-section-clients .wrapper-content .content-element {
    text-align: center;
    width: 100%;
  }
  section.faktoring-section-advantages {
    padding: 60px 0;
  }
  section.faktoring-section-advantages .wrapper-content {
    flex-direction: column;
  }
  section.faktoring-section-advantages .wrapper-content .content-text {
    width: 100%;
  }
  section.faktoring-section-advantages .wrapper-content .content-images {
    width: 100%;
    margin-top: 40px;
  }
  section.faktoring-section-footer .faktoring-wrapper-form .checkbox-wrapper {
    margin: 20px 0 0;
    height: 60px;
  }
  section.faktoring-section-footer .footer-flexbox {
    flex-direction: column;
    align-items: center;
  }
  section.faktoring-section-footer .footer-inner {
    margin: 3px 0;
    flex-direction: column;
    align-items: center;
  }
  section.faktoring-section-footer .footer-inner ul li {
    margin: 0 5px;
  }
  section.faktoring-section-footer .footer-inner h2 {
    margin-bottom: 5px;
  }
  section.faktoring-section-footer .footer-inner a {
    margin: 0;
  }
  section.section-footer .footer-flexbox {
    padding: 0;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  section.section-footer .footer-flexbox .flexbox-service:first-of-type {
    margin-top: 0;
  }
  section.section-footer .alert {
    width: 100%;
    margin: 30px 0 0 0;
  }
  section.section-footer .footer-credits-wrapper .footer-credits {
    text-align: center;
    justify-content: center;
  }
}
@media (max-width: 600.98px) {
  section.section-welcome {
    height: 100%;
  }
  section.section-welcome .welcome-background .owl-carousel-wrapper {
    width: 90%;
    height: 40%;
    transform: translate(-50%, -50%);
    border-left: 2px solid rgba(255, 255, 255, 0.9);
  }
  section.section-welcome .welcome-background .owl-carousel-wrapper .owl-carousel-welcome .owl-item-wrapper h2 {
    font-size: 20px;
  }
  section.section-welcome .welcome-background .owl-carousel-wrapper .owl-element {
    display: none;
  }
  section.section-welcome .welcome-background .background-right-col {
    padding: 130% 0 0 0;
    width: 100%;
  }
  section.section-welcome .welcome-background .background-right-col h3 {
    text-align: center;
    margin: 0 0 30px 0;
  }
  section.section-welcome .welcome-background .background-left-col {
    display: none;
  }
  section.section-about .section-wrapper .about-description {
    padding: 0;
  }
  section.section-about .section-wrapper .about-image .color-element {
    height: 100px;
    width: 120px;
  }
  section.section-icons .section-wrapper {
    justify-content: space-around;
  }
  section.section-icons .section-wrapper .icon h3 {
    font-size: 13px;
  }
  section.section-offer .section-background {
    padding: 40px 0 30px 0;
  }
  section.section-offer .section-background .heading-mobile {
    display: block;
  }
  section.section-offer .section-background .heading-mobile h3 {
    color: #fff;
  }
  section.section-offer .section-background .owl-carousel-wrapper {
    position: static;
    top: 0;
    left: 0;
    transform: translate(0);
  }
  section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer {
    padding-top: 50px;
  }
  section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .heading {
    width: 90%;
  }
  section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .heading h3 {
    font-size: 26px;
  }
  section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .description {
    width: 90%;
    text-align: center;
    margin: 20px 0 0 0;
    justify-content: flex-start;
  }
  section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .description p {
    text-align: center;
  }
  section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .item .description .btn-default {
    align-self: center;
  }
  section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-nav {
    position: absolute;
    top: 0;
    width: 100%;
  }
  section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-nav .owl-next:hover,
  section.section-offer .section-background .owl-carousel-wrapper .owl-carousel-offer .owl-controls .owl-nav .owl-prev:hover {
    transform: translatex(0);
  }
  section.faq-section-content .img-absolute {
    display: none;
  }
  section.faq-section-content .faq-content {
    width: 90%;
    margin: 0 auto;
  }
  section.kontakt-section-content .section-wrapper h2 {
    text-align: center;
    font-size: 22px;
  }
  section.kontakt-section-content .section-wrapper h2 strong {
    font-size: 28px;
  }
  section.kontakt-section-content .section-wrapper .section-information {
    flex-direction: column;
  }
  section.kontakt-section-content .section-wrapper .section-information .information-column {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 575.98px) {
  .checkbox-wrapper {
    height: 60px;
  }
  section.faktoring-section-welcome {
    padding: 30px 0 0 0;
  }
  section.faktoring-section-welcome .wrapper-text h2,
  section.faktoring-section-welcome .wrapper-text h3 {
    text-align: center;
  }
  section.faktoring-section-welcome .wrapper-text h2 {
    font-size: 70px;
    line-height: 70px;
  }
  section.faktoring-section-welcome .wrapper-text h3 {
    font-size: 32px;
    line-height: 42px;
  }
  section.faktoring-section-welcome .wrapper-text p {
    font-size: 13px;
  }
  section.faktoring-section-welcome .wrapper-text .content-elements {
    padding-left: 0;
  }
  .faktoring-wrapper-form .form-inner {
    gap: 10px;
  }
  .faktoring-wrapper-form .form-inner .input-service {
    width: 100%;
  }
  section.faktoring-section-about .wrapper-content .content-description .box-inner {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .page-heading h1 {
    width: 95%;
    font-size: 46px;
  }
  .heading-element {
    margin-top: 0;
  }
  .heading-element h2 {
    font-size: 36px;
  }
  nav.section-header .header-flexbox {
    padding: 8px 0;
  }
  section.section-about {
    padding: 40px 0 60px 0;
  }
  section.section-about .section-wrapper .about-description {
    text-align: center;
  }
  section.section-about .section-wrapper .about-description p {
    text-align: center;
  }
  section.section-about .section-wrapper .about-description p:not(:first-of-type) {
    text-align: center;
  }
  section.section-about .section-wrapper .about-description h3 strong {
    font-size: 28px;
  }
  section.section-icons {
    padding: 20px 0 40px 0;
  }
  section.section-team {
    padding: 40px 0;
  }
  section.section-team .team-description {
    width: 100%;
    text-align: center;
  }
  section.section-team .section-wrapper .person {
    width: 100%;
  }
  section.section-team .section-wrapper .person:hover .person-description {
    height: 85%;
  }
  section.section-partners {
    padding: 30px 0;
  }
  section.section-partners .heading-mobile {
    display: block;
  }
  section.section-partners .owl-carousel-partners {
    margin-top: 20px;
  }
  section.section-news {
    padding: 40px 0 100px 0;
  }
  section.section-news .news-wrapper {
    padding: 0;
  }
  section.section-news .heading-mobile {
    display: block;
  }
  section.o-nas-section-content .section-content .section-element p,
  section.o-nas-section-content .section-content .section-element.reverse p {
    text-align: center;
  }
  section.o-nas-section-content .section-content .section-element .img img,
  section.o-nas-section-content .section-content .section-element.reverse .img img {
    width: 80px;
  }
  section.o-nas-section-content .section-team {
    padding-top: 40px;
  }
  section.o-nas-section-content .section-team h2 {
    text-align: center;
  }
  section.o-nas-section-content .section-team .team-wrapper {
    padding-top: 20px;
  }
  section.o-nas-section-content .section-team .team-wrapper .person,
  section.o-nas-section-content .section-team .team-wrapper .person:nth-of-type(even),
  section.o-nas-section-content .section-team .team-wrapper .person:not(:first-of-type) {
    flex-direction: column;
    padding-top: 50px;
  }
  section.o-nas-section-content .section-team .team-wrapper .person .person-img,
  section.o-nas-section-content .section-team .team-wrapper .person:nth-of-type(even) .person-img,
  section.o-nas-section-content .section-team .team-wrapper .person:not(:first-of-type) .person-img {
    width: 60%;
    height: 250px;
  }
  section.o-nas-section-content .section-team .team-wrapper .person .person-img:before,
  section.o-nas-section-content .section-team .team-wrapper .person:nth-of-type(even) .person-img:before,
  section.o-nas-section-content .section-team .team-wrapper .person:not(:first-of-type) .person-img:before {
    height: 115%;
  }
  section.o-nas-section-content .section-team .team-wrapper .person .person-description,
  section.o-nas-section-content .section-team .team-wrapper .person:nth-of-type(even) .person-description,
  section.o-nas-section-content .section-team .team-wrapper .person:not(:first-of-type) .person-description {
    margin-top: 50px;
    width: 100%;
    padding: 0;
  }
  section.o-nas-section-content .section-team .team-wrapper .person .person-description p,
  section.o-nas-section-content .section-team .team-wrapper .person:nth-of-type(even) .person-description p,
  section.o-nas-section-content .section-team .team-wrapper .person:not(:first-of-type) .person-description p {
    text-align: left !important;
  }
  section.o-nas-section-content .section-carreer {
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
  }
  section.o-nas-section-content .section-carreer h3 {
    font-size: 24px;
    line-height: 32px;
  }
  section.o-nas-section-content .section-carreer .link-color {
    margin: 40px 0 0 0;
  }
  section.offer-page-section-content {
    padding-bottom: 50px;
  }
  section.offer-page-section-content .img-absolute {
    display: none;
  }
  section.offer-page-section-content .heading-element {
    margin-top: 40px;
  }
  section.offer-page-section-content .section-content .description {
    padding-top: 40px;
    text-align: center;
  }
  section.offer-page-section-content .section-content .description p {
    text-align: center;
  }
  section.offer-page-section-content .section-content .section-element,
  section.offer-page-section-content .section-content .section-element:nth-of-type(even) {
    margin-top: 40px;
  }
  section.offer-page-section-content .section-content .section-element .section-text h4,
  section.offer-page-section-content .section-content .section-element:nth-of-type(even) .section-text h4,
  section.offer-page-section-content .section-content .section-element .section-text p,
  section.offer-page-section-content .section-content .section-element:nth-of-type(even) .section-text p {
    text-align: center;
  }
  section.offer-page-section-content .section-content .section-element .section-text h4,
  section.offer-page-section-content .section-content .section-element:nth-of-type(even) .section-text h4 {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 15px;
  }
  section.offer-page-section-content .section-content .section-element .section-text .elements-wrapper,
  section.offer-page-section-content .section-content .section-element:nth-of-type(even) .section-text .elements-wrapper {
    flex-direction: column;
    align-items: center;
  }
  section.offer-page-section-content .section-content .section-element .section-text .elements-wrapper .element,
  section.offer-page-section-content .section-content .section-element:nth-of-type(even) .section-text .elements-wrapper .element {
    width: 90%;
    text-align: center;
  }
  section.offer-page-section-content .section-cta {
    margin: 40px 0 !important;
  }
  section.offer-page-section-content .section-cta .color-element {
    display: none;
  }
  section.offer-page-section-content .section-cta a {
    font-size: 22px;
    padding: 0;
  }
  section.aktualnosc-section-content {
    padding-bottom: 80px;
  }
  section.aktualnosc-section-content .section-content .description p {
    text-align: center;
  }
  section.faq-section-content {
    padding-bottom: 60px;
  }
  section.faq-section-content .faq-content .faq-item span {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  section.faq-section-content .faq-content .faq-item span:before {
    width: 15px;
    height: 15px;
    top: 5px;
  }
  section.faq-section-content .faq-content .faq-item p {
    text-align: center;
  }
  section.static-page-section-content {
    overflow: hidden;
  }
  section.static-page-section-content .owl-carousel-wrapper {
    margin-top: 20px;
  }
  section.static-page-section-content .section-content .description {
    padding-top: 40px;
    text-align: center;
  }
  section.static-page-section-content .section-content .description p {
    text-align: center;
  }
  section.static-page-section-content .section-content .description ul {
    text-align: left;
  }
  section.static-page-section-content .section-cooperation {
    padding-top: 40px;
  }
  section.static-page-section-content .section-cooperation .section-text {
    text-align: center;
  }
  section.static-page-section-content .section-cooperation .section-text p {
    text-align: center;
  }
  section.kontakt-section-content {
    padding-bottom: 40px;
  }
  section.kontakt-section-content .section-wrapper .heading {
    text-align: center;
  }
  section.kontakt-section-content .section-wrapper .section-team {
    justify-content: center;
  }
  section.kontakt-section-content .section-wrapper .section-team .person {
    width: 80%;
    margin: 0;
  }
}
