// Large devices (desktops, less than 1400px)
@media (max-width: 1410.98px) {
  section.offer-page-section-content {
    .img-absolute {
      width: 200px;
      left: -100px;
    }
  }

  section.section-footer {
    .footer-flexbox {
      padding-top: 20px;
    }
  }
}

// Large devices (desktops, less than 1280px)
@media (max-width: 1280.98px) {
  section.section-welcome {
    .welcome-background {
      .background-right-col {
        padding-right: 20px;
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1190.98px) {
  .section-heading {
    display: none;
  }

  nav.section-header {
    .header-flexbox {
      padding: 20px 0;

      .flexbox-logo {
        img {
          height: 45px;
        }
      }

      .flexbox-nav-wrapper {
        .flexbox-nav-inner {
          .flexbox-nav {
            li {
              padding: 0 15px;
            }
          }
        }
      }
    }
  }

  section.section-welcome {
    .welcome-background {
      .owl-carousel-wrapper {
        width: 400px;

        .owl-carousel-welcome {
          .owl-controls {
            transform: translate(190%, -50%);
          }

          .owl-item-wrapper {
            h2 {
              font-size: 28px;
            }
          }
        }
      }
    }
  }

  section.section-offer {
    .section-background {
      .owl-carousel-wrapper {
        .owl-carousel-offer {
          .item {
            .description {
              padding: 0;
              width: 35%;
            }

            .heading {
              width: 40%;
            }
          }

          .owl-controls {
            .owl-dots {
              right: 30px;
            }
          }
        }
      }
    }
  }

  section.section-team {
    .section-wrapper {
      .person {
        width: 49.2%;
      }
    }
  }

  section.offer-page-section-content {
    .img-absolute {
      width: 200px;
    }

    .section-content {
      .section-element {
        .section-text {
          .element {
            width: 49.2%;
            height: 200px;
          }
        }
      }
    }

    .section-cta {
      a {
        font-size: 22px;
      }
    }
  }

  section.faktoring-section-welcome {
    .faktoring-wrapper-form {
      margin-top: 30px;
      width: 100%;
    }

    .wrapper-text {
      width: 100%;
      padding-left: 0;

      .text-content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      h2,
      h3 {
        text-align: center;
      }

      .content-elements {
        padding-left: 70px;
      }
    }

    .wrapper-image {
      display: none;
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 992.98px) {
  .link-color {
    font-size: 12px;
    width: 220px;
    height: 70px;
  }

  .news-wrapper {
    .navigation {
      width: 90%;
      margin-bottom: 20px;

      p {
        text-align: center;
        font-weight: 600;
      }

      ul {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        li {
          padding: 10px 20px;
        }
      }
    }

    .news-content {
      width: 100%;
    }
  }

  nav.section-header {
    .header-flexbox {
      justify-content: space-between;

      .flexbox-logo {
        width: 20%;
      }

      .flexbox-contact {
        display: none;
      }

      .flexbox-nav-wrapper.nav-page {
        width: 80%;
      }
    }
  }

  nav.faktoring-section-header {
    .header-flexbox {
      .flexbox-nav-wrapper {
        .flexbox-nav-inner {
          .flexbox-nav {
            li {
              a {
                font-size: 13px;
              }

              &:not(:last-of-type) {
                margin-right: 13px;
              }
            }
          }
        }
      }
    }
  }

  section.section-welcome {
    .welcome-background {
      .owl-carousel-wrapper {
        .owl-carousel-welcome {
          .owl-controls {
            top: 110%;
            left: 35%;
            transform: translate(0);

            .owl-dots {
              transform: rotate(0);
            }
          }
        }
      }
    }
  }

  section.section-about {
    .section-wrapper {
      flex-direction: column;

      .about-description {
        width: 100%;
      }

      .about-image {
        margin-top: 50px;
        width: 70%;
      }
    }
  }

  section.section-offer {
    height: 800px;

    .section-background {
      flex-direction: column;

      .background-left-col,
      .background-right-col {
        width: 100%;
        height: 38%;
      }

      .owl-carousel-wrapper {
        height: 85%;

        .owl-carousel-offer {
          .item {
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .description {
              padding: 0;
              margin: 30px 0 20px 0;
              width: 70%;
            }

            .heading {
              width: 60%;
            }
          }

          .owl-controls {
            .owl-dots {
              bottom: 520px;
            }
          }
        }
      }
    }
  }

  section.section-team {
    .section-wrapper {
      .person {
        width: 49%;
      }
    }
  }

  section.section-news {
    padding: 60px 0 100px 0;

    .news {
      width: 48%;
    }
  }

  section.o-nas-section-content {
    .section-content {
      .section-gallery {
        flex-direction: column;

        .item {
          &.big {
            width: 80%;
          }
        }

        .items-wrapper {
          width: 80%;
          margin-left: 0;
        }
      }
    }

    .section-team {
      .team-wrapper {
        .person {
          .person-img {
            width: 42%;
          }

          .person-description {
            width: 50%;
          }
        }
      }
    }

    .section-carreer {
      h3 {
        font-size: 28px;
      }
    }
  }

  section.offer-page-section-content {
    .img-absolute {
      width: 150px;
    }

    .section-content {
      .section-element {
        .section-text {
          .element {
            width: 49%;
          }
        }
      }
    }

    .section-cta {
      .color-element {
        left: 3%;
      }

      a {
        font-size: 22px;
        padding: 0 100px;
      }
    }
  }

  section.kontakt-section-content {
    .section-wrapper {
      .section-team {
        .person {
          width: 31%;
        }
      }

      .section-map {
        flex-direction: column;

        .map-wrapper {
          width: 100%;
          order: 1;
          margin-bottom: 20px;
        }

        .section-form {
          width: 100%;
          order: 2;
        }
      }
    }
  }

  section.faktoring-section-about {
    padding-top: 60px;

    .wrapper-content {
      flex-direction: column;

      header {
        width: 100%;
        padding-right: 0;
      }

      .content-description {
        margin-top: 40px;
        width: 100%;
      }
    }
  }
}

// Small devices (landscape phones, less than 789px)
@media (max-width: 790.98px) {
  .section-form {
    width: 100%;
    margin: 60px 0 0;
  }

  .page-heading {
    text-align: center;
    padding: 40px 0;

    h1 {
      font-size: 50px;
    }

    p {
      width: 95%;
      font-size: 16px;
    }
  }

  .pagination-nav {
    padding: 40px 0 0 0;
  }

  .nav-button {
    display: flex;

    &#nav-button-welcome {
      &.hidden {
        display: flex;
      }
    }

    &.hidden {
      display: flex;

      &#nav-button-page {
        .button-bar {
          background-color: #000;
        }
      }
    }
  }

  .nav-inner {
    .nav-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;

      nav.section-header {
        .header-flexbox {
          .flexbox-nav-wrapper.nav-welcome {
            opacity: 1;
            visibility: visible;
            transition: all 0.3s;
          }
        }
      }
    }
  }

  nav.section-header {
    width: 100%;

    &.hidden {
      display: block;
      width: 100%;
      height: 100%;
      visibility: visible;
      opacity: 1;
    }

    &.bg-color-light {
      background-color: rgba(@color1, 0.7);

      .header-flexbox {
        .flexbox-logo {
          width: auto;

          &.hidden {
            display: block;
          }

          img {
            height: 35px;
          }
        }

        .list-contact {
          display: flex;
        }

        .flexbox-nav-wrapper {
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          overflow-y: scroll;
          background: #fff;
          z-index: 555;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s;

          &.nav-page {
            width: 100%;

            &.active {
              opacity: 1;
              visibility: visible;
            }
          }

          .nav-button-close {
            display: flex;
            justify-content: flex-end;
          }

          .flexbox-nav-inner {
            .flexbox-nav {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 10px 0;
              height: 100%;

              li {
                padding: 10px 0;

                &.mobile {
                  display: flex;
                }

                &.desktop {
                  display: none;
                }

                a {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }

  nav.faktoring-section-header {
    .header-flexbox {
      .nav-button {
        display: flex;
        margin-right: -10px;
      }

      .flexbox-nav-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        background: #fff;
        z-index: 999;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;

        .nav-button-close {
          display: block;
        }

        .flexbox-nav-inner {
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 100%;
          padding: 50px;
          margin-right: 0;

          .flexbox-nav {
            display: flex;
            flex-direction: column;
            align-items: center;

            li {
              padding: 5px 0;

              a {
                font-size: 15px;
              }
            }
          }
        }

        &.active {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  section.section-welcome {
    .welcome-background {
      .owl-carousel-wrapper {
        width: 400px;
        height: 250px;
        transform: translate(-25%, -50%);

        .owl-element {
          width: 250px;
          top: 78px;
          left: -170px;
        }
      }

      .background-left-col {
        width: 34%;

        .social-media {
          span {
            font-size: 12px;
          }

          img {
            width: 40px;
          }
        }
      }

      .background-right-col {
        width: 66%;

        h3 {
          font-size: 12px;
          margin: 0 0 42px 20px;
          text-align: right;
        }
      }
    }
  }

  section.section-offer {
    height: 100%;

    .section-background {
      padding: 50px 0 30px 0;
      height: auto;

      .owl-carousel-wrapper {
        position: static;
        top: 0;
        left: 0;
        transform: translate(0);

        .owl-carousel-offer {
          .owl-controls {
            .owl-dots {
              display: none !important;
            }
          }
        }
      }

      .background-left-col,
      .background-right-col {
        display: none;
      }
    }
  }

  section.section-team {
    .section-wrapper {
      .person {
        height: 200px;
        width: 48.5%;

        .person-description {
          .description {
            font-size: 12px;
            line-height: 16px;
          }
        }

        &:hover .person-description {
          height: 90%;
        }
      }
    }
  }

  section.o-nas-section-content {
    padding-bottom: 100px;

    .section-content {
      .heading-element {
        padding: 50px 0 20px 0;
      }

      .section-element,
      .section-element.reverse {
        flex-direction: column;
        padding-top: 0;

        .text {
          width: 100%;
        }

        .img {
          margin-top: 50px;

          img {
            width: 120px;
          }
        }
      }
    }

    .section-team {
      .team-wrapper {
        .person,
        .person:nth-of-type(even),
        .person:not(:first-of-type) {
          flex-direction: column;
          padding-top: 50px;

          .person-img {
            width: 60%;
          }

          .person-description {
            margin-top: 50px;
            width: 100%;
          }
        }
      }
    }

    .section-carreer {
      h3 {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  section.offer-page-section-content {
    .img-absolute {
      width: 120px;
    }

    .section-content {
      .section-element {
        .section-text {
          display: flex;
          flex-direction: column;
          align-items: center;

          .elements-wrapper {
            flex-direction: column;
            align-items: center;

            .element {
              width: 70%;
              text-align: center;
            }
          }
        }
      }
    }

    .section-cta {
      .color-element {
        left: -3%;
      }

      a {
        font-size: 18px;
        padding: 0 50px;
      }
    }
  }

  section.section-news {
    .news {
      width: 100%;
      margin: 0 0 8px 0;

      &.news-lg {
        flex-direction: column;
        margin: 0 0 8px 0;

        .news-img {
          display: none;
        }

        .news-description {
          width: 100%;
          padding: 20px;
          background-color: #f5f5f5;

          h3 {
            font-size: 20px;
          }
        }
      }
    }

    .news-wrapper {
      flex-direction: column;

      .navigation {
        width: 100%;
      }
    }
  }

  section.aktualnosci-section-content {
    padding-bottom: 80px;

    .news-wrapper {
      padding-top: 40px;

      .news-content {
        .news-item {
          flex-direction: column;
          align-items: center;

          .news-img {
            width: 90%;
          }

          .news-description {
            margin-top: 20px;
            width: 90%;
            margin-left: 0;
          }
        }
      }
    }
  }

  section.aktualnosc-section-content {
    .section-content {
      flex-direction: column;
      align-items: center;

      .img {
        width: 80%;
        height: 100%;
        justify-content: center;

        .btn-default {
          align-self: center;
          font-size: 14px;
        }
      }

      .description {
        width: 100%;
        margin-top: 20px;
      }
    }
  }

  section.faq-section-content {
    .img-absolute {
      right: -20%;
    }
  }

  section.static-page-section-content {
    padding-bottom: 100px;

    .section-cooperation {
      flex-direction: column;

      .section-text {
        width: 100%;
      }

      .section-contact {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 40px;

        .link-color {
          margin-left: 0;
        }
      }
    }
  }

  section.kontakt-section-content {
    .section-wrapper {
      .section-team {
        justify-content: space-between;

        .person {
          width: 45%;
        }
      }
    }
  }

  section.faktoring-section-clients {
    padding-top: 60px;

    .wrapper-content {
      flex-direction: column;

      .content-element {
        text-align: center;
        width: 100%;
      }
    }
  }

  section.faktoring-section-advantages {
    padding: 60px 0;

    .wrapper-content {
      flex-direction: column;

      .content-text {
        width: 100%;
      }

      .content-images {
        width: 100%;
        margin-top: 40px;
      }
    }
  }

  section.faktoring-section-footer {
    .faktoring-wrapper-form {
      .checkbox-wrapper {
        margin: 20px 0 0;
        height: 60px;
      }
    }

    .footer-flexbox {
      flex-direction: column;
      align-items: center;
    }

    .footer-inner {
      margin: 3px 0;
      flex-direction: column;
      align-items: center;

      ul {
        li {
          margin: 0 5px;
        }
      }

      h2 {
        margin-bottom: 5px;
      }

      a {
        margin: 0;
      }
    }
  }

  section.section-footer {
    .footer-flexbox {
      padding: 0;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .flexbox-service:first-of-type {
        margin-top: 0;
      }
    }

    .alert {
      width: 100%;
      margin: 30px 0 0 0;
    }

    .footer-credits-wrapper {
      .footer-credits {
        text-align: center;
        justify-content: center;
      }
    }
  }
}

// Extra small devices (portrait phones, less than 599px)
@media (max-width: 600.98px) {
  section.section-welcome {
    height: 100%;

    .welcome-background {
      .owl-carousel-wrapper {
        width: 90%;
        height: 40%;
        transform: translate(-50%, -50%);
        border-left: 2px solid rgba(255, 255, 255, 0.9);

        .owl-carousel-welcome {
          .owl-item-wrapper {
            h2 {
              font-size: 20px;
            }
          }
        }

        .owl-element {
          display: none;
        }
      }

      .background-right-col {
        padding: 130% 0 0 0;
        width: 100%;

        h3 {
          text-align: center;
          margin: 0 0 30px 0;
        }
      }

      .background-left-col {
        display: none;
      }
    }
  }

  section.section-about {
    .section-wrapper {
      .about-description {
        padding: 0;
      }

      .about-image {
        .color-element {
          height: 100px;
          width: 120px;
        }
      }
    }
  }

  section.section-icons {
    .section-wrapper {
      justify-content: space-around;

      .icon {
        h3 {
          font-size: 13px;
        }
      }
    }
  }

  section.section-offer {
    .section-background {
      padding: 40px 0 30px 0;

      .heading-mobile {
        display: block;

        h3 {
          color: #fff;
        }
      }

      .owl-carousel-wrapper {
        position: static;
        top: 0;
        left: 0;
        transform: translate(0);

        .owl-carousel-offer {
          padding-top: 50px;

          .item {
            .heading {
              width: 90%;

              h3 {
                font-size: 26px;
              }
            }

            .description {
              width: 90%;
              text-align: center;
              margin: 20px 0 0 0;
              justify-content: flex-start;

              p {
                text-align: center;
              }

              .btn-default {
                align-self: center;
              }
            }
          }

          .owl-controls {
            .owl-nav {
              position: absolute;
              top: 0;
              width: 100%;

              .owl-next,
              .owl-prev {
                &:hover {
                  transform: translatex(0);
                }
              }
            }
          }
        }
      }
    }
  }

  section.faq-section-content {
    .img-absolute {
      display: none;
    }

    .faq-content {
      width: 90%;
      margin: 0 auto;
    }
  }

  section.kontakt-section-content {
    .section-wrapper {
      h2 {
        text-align: center;
        font-size: 22px;

        strong {
          font-size: 28px;
        }
      }

      .section-information {
        flex-direction: column;

        .information-column {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .checkbox-wrapper {
    height: 60px;
  }

  section.faktoring-section-welcome {
    padding: 30px 0 0 0;

    .wrapper-text {
      h2,
      h3 {
        text-align: center;
      }

      h2 {
        font-size: 70px;
        line-height: 70px;
      }

      h3 {
        font-size: 32px;
        line-height: 42px;
      }

      p {
        font-size: 13px;
      }

      .content-elements {
        padding-left: 0;
      }
    }
  }

  .faktoring-wrapper-form {
    .form-inner {
      gap: 10px;

      .input-service {
        width: 100%;
      }
    }
  }

  section.faktoring-section-about {
    .wrapper-content {
      .content-description {
        .box-inner {
          width: 100%;
        }
      }
    }
  }
}

// Extra small devices (portrait phones, less than 499px)
@media (max-width: 500px) {
  .page-heading {
    h1 {
      width: 95%;
      font-size: 46px;
    }
  }

  .heading-element {
    margin-top: 0;

    h2 {
      font-size: 36px;
    }
  }

  nav.section-header {
    .header-flexbox {
      padding: 8px 0;
    }
  }

  section.section-about {
    padding: 40px 0 60px 0;

    .section-wrapper {
      .about-description {
        text-align: center;

        p {
          text-align: center;

          &:not(:first-of-type) {
            text-align: center;
          }
        }

        h3 {
          strong {
            font-size: 28px;
          }
        }
      }
    }
  }

  section.section-icons {
    padding: 20px 0 40px 0;
  }

  section.section-team {
    padding: 40px 0;

    .team-description {
      width: 100%;
      text-align: center;
    }

    .section-wrapper {
      .person {
        width: 100%;

        &:hover .person-description {
          height: 85%;
        }
      }
    }
  }

  section.section-partners {
    padding: 30px 0;

    .heading-mobile {
      display: block;
    }

    .owl-carousel-partners {
      margin-top: 20px;
    }
  }

  section.section-news {
    padding: 40px 0 100px 0;

    .news-wrapper {
      padding: 0;
    }

    .heading-mobile {
      display: block;
    }
  }

  section.o-nas-section-content {
    .section-content {
      .section-element,
      .section-element.reverse {
        p {
          text-align: center;
        }

        .img {
          img {
            width: 80px;
          }
        }
      }
    }

    .section-team {
      padding-top: 40px;

      h2 {
        text-align: center;
      }

      .team-wrapper {
        padding-top: 20px;

        .person,
        .person:nth-of-type(even),
        .person:not(:first-of-type) {
          flex-direction: column;
          padding-top: 50px;

          .person-img {
            width: 60%;
            height: 250px;

            &:before {
              height: 115%;
            }
          }

          .person-description {
            margin-top: 50px;
            width: 100%;
            padding: 0;

            p {
              text-align: left !important;
            }
          }
        }
      }
    }

    .section-carreer {
      flex-direction: column;
      align-items: center;
      padding-top: 60px;

      h3 {
        font-size: 24px;
        line-height: 32px;
      }

      .link-color {
        margin: 40px 0 0 0;
      }
    }
  }

  section.offer-page-section-content {
    padding-bottom: 50px;

    .img-absolute {
      display: none;
    }

    .heading-element {
      margin-top: 40px;
    }

    .section-content {
      .description {
        padding-top: 40px;
        text-align: center;

        p {
          text-align: center;
        }
      }

      .section-element,
      .section-element:nth-of-type(even) {
        margin-top: 40px;

        .section-text {
          h4,
          p {
            text-align: center;
          }

          h4 {
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 15px;
          }

          .elements-wrapper {
            flex-direction: column;
            align-items: center;

            .element {
              width: 90%;
              text-align: center;
            }
          }
        }
      }
    }

    .section-cta {
      margin: 40px 0 !important;

      .color-element {
        display: none;
      }

      a {
        font-size: 22px;
        padding: 0;
      }
    }
  }

  section.aktualnosc-section-content {
    padding-bottom: 80px;

    .section-content {
      .description {
        p {
          text-align: center;
        }
      }
    }
  }

  section.faq-section-content {
    padding-bottom: 60px;

    .faq-content {
      .faq-item {
        span {
          text-align: center;
          font-size: 18px;
          font-weight: 600;

          &:before {
            width: 15px;
            height: 15px;
            top: 5px;
          }
        }

        p {
          text-align: center;
        }
      }
    }
  }

  section.static-page-section-content {
    overflow: hidden;

    .owl-carousel-wrapper {
      margin-top: 20px;
    }

    .section-content {
      .description {
        padding-top: 40px;
        text-align: center;

        p {
          text-align: center;
        }

        ul {
          text-align: left;
        }
      }
    }

    .section-cooperation {
      padding-top: 40px;

      .section-text {
        text-align: center;

        p {
          text-align: center;
        }
      }
    }
  }

  section.kontakt-section-content {
    padding-bottom: 40px;

    .section-wrapper {
      .heading {
        text-align: center;
      }

      .section-team {
        justify-content: center;

        .person {
          width: 80%;
          margin: 0;
        }
      }
    }
  }
}
