@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;800&display=swap");

body {
  min-height: 100vh;
  height: 100%;
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.no-scroll {
    overflow: hidden;
    max-height: 100vh;
    padding-right: 20px;
  }

  ul,
  ol,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none !important;
  }

  button {
    border: none;
    outline: none;

    &:focus {
      box-shadow: none !important;
    }
  }

  ul {
    list-style: none;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ddd;

    &:hover {
      background-color: rgba(@color1, 0.8);
    }
  }
}

@color1: #ff6c00;

.modal {
  .modal-content {
    padding: 20px 30px;
  }

  .modal-body {
    padding: 30px 15px;

    p {
      margin-top: 10px !important;
    }
  }

  .modal-footer {
    padding: 20px 0 10px 0;

    .btn-default {
      margin-top: 0 !important;
      width: 150px;
    }
  }
}

.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;

  .alert-service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    transform: translateX(8px);
    padding: 50px 0;

    .service-inner {
      padding: 50px;
      background: #fff;
      width: 100%;
      box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
      text-align: center;
      border-top: 5px solid #fff;
      position: relative;
      transform: scale(0.8);
      transition: all 0.5s;

      .inner-dismiss {
        top: 10px;
        right: 10px;
        position: absolute;
        border: 0;
        background: none;
        padding: 0;
        cursor: pointer;
        display: flex;
        padding: 5px;
        outline: none;

        img {
          height: 15px;
        }
      }

      &.success {
        border-top: 5px solid #78b042;
      }

      &.warning {
        border-top: 5px solid #d51a27;
      }

      &.info {
        border-top: 5px solid #1a88d5;
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;

    .alert-service {
      .service-inner {
        transform: none;
      }
    }
  }
}

.section-form {
  margin: 60px auto 0;
  width: 75%;
  padding: 30px;
  background-color: #f5f5f5;

  .section-heading {
    margin-bottom: 20px;
  }

  .form-element {
    display: flex;
    flex-direction: column;
    align-items: center;

    .input-wrapper,
    input,
    textarea {
      width: 100%;
      background-color: transparent;
    }

    label {
      margin: 30px 0 20px 0;
      text-align: left;
      font-size: 13px;
      width: 100%;
    }
  }

  select {
    width: 100%;
    display: block;
    border: none;
    border-radius: 0;
    padding: 0;
    font-size: 14px;
    margin: 10px 0;
    background-color: transparent;
    cursor: pointer;
  }

  option {
    font-size: 14px;
    padding: 10px;
    background-color: transparent;
  }

  select,
  textarea,
  input {
    transition: all 0.2s;
    border: none;
    border-bottom: 1px solid @color1;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;

    &:focus {
      outline: none;
      border-bottom: 1px solid #000;
    }
  }

  textarea {
    height: 80px;
  }

  .info {
    margin-top: 20px;

    p {
      color: #000;
      font-size: 11px;
    }
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .formal {
    padding: 10px 10px 10px 0;
    height: 60px;
    overflow: auto;
    margin: 10px 0;

    p {
      font-size: 10px;
      line-height: 18px;
      color: #000;
    }
  }
}

.checkbox-wrapper {
  position: relative;
  cursor: pointer;
  display: block;
  margin-bottom: 0;
  height: 30px;

  .radio-box {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    font-size: 13px;
    text-transform: none;
    color: #000;
    text-align: left;
    transition: all 0.3s;
    padding: 0;
    font-weight: 500;

    &:focus {
      border: none;
    }

    &:before {
      content: "";
      min-width: 16px;
      max-width: 16px;
      height: 16px;
      display: block;
      background: url("../img/icons/checkbox.svg") no-repeat;
      margin-right: 10px;
      transition: all 0.3s;
    }

    p {
      font-size: 11px;
    }
  }

  input {
    width: auto !important;
    height: auto;
    position: absolute;
    left: 0;
    opacity: 0;

    &:focus {
      box-shadow: none;
    }
  }
}

.checkbox-wrapper > input:checked + .radio-box {
  font-weight: 700;

  &:before {
    background: url("../img/icons/checkbox-checked.svg") no-repeat;
  }
}

// keyframes

@keyframes navOpen {
  from {
    width: 0%;
    transition-timing-function: ease-in;
  }

  to {
    width: 65%;
    transition-timing-function: ease-out;
  }
}

@keyframes navClose {
  from {
    width: 65%;
    transition-timing-function: ease-in;
  }

  to {
    width: 0%;
    transition-timing-function: ease-out;
  }
}

nav.section-header {
  display: block;
  z-index: 111;

  &.bg-color-light {
    background-color: #f5f5f5;

    .header-flexbox {
      .flexbox-nav-wrapper {
        .flexbox-nav-inner {
          .flexbox-nav {
            .mobile {
              display: none;

              button {
                background-color: transparent;
                color: #444;
              }
            }

            .desktop {
              .dropdown-toggle {
                outline: none;
                border: none;
                font-size: 14px;
                color: #444;
                background: none;
                cursor: pointer;
                transition: color 350ms;

                &:hover {
                  color: @color1;
                }

                &::after {
                  display: none;
                }
              }

              .dropdown-menu {
                position: relative;
                background: rgba(0, 0, 0, 0.75);
                border-radius: 0;
                margin-top: 10px;

                &:before {
                  content: "";
                  display: block;
                  position: absolute;
                  left: 25px;
                  top: -6px;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 4px 5px 4px;
                  border-color: transparent transparent rgba(0, 0, 0, 0.75)
                    transparent;
                }

                .dropdown-item {
                  padding: 8px;
                  font-size: 13px;
                  font-weight: 700;
                  transition: all 0.3s;
                  transform: none !important;
                  color: #fff;

                  &:hover {
                    background: rgba(255, 255, 255, 0.1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .header-flexbox {
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .flexbox-logo {
      width: 30%;
      display: flex;
      justify-content: flex-end;

      img {
        height: 60px;
        width: auto;
      }
    }

    .list-contact {
      display: none;

      a {
        img {
          width: 25px;
          margin: 0 10px;
        }
      }
    }

    .flexbox-nav-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 70%;

      &.nav-welcome {
        position: fixed;
        top: 0;
        left: -20px;
        height: 100%;
        width: 0;
        background: #fff;
        z-index: 555;

        .nav-button-close {
          display: flex;
          justify-content: flex-end;
        }

        .flexbox-nav-inner {
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 100%;
          padding: 50px 30px;
          margin-right: 0;

          .flexbox-nav {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            li {
              padding: 10px 0;

              a {
                font-size: 18px;
              }

              &.desktop {
                display: none;
              }
            }
          }
        }

        &.navOpen {
          animation: navOpen 600ms both;

          .nav-button-close {
            top: 30px;
          }
        }

        &.navClose {
          animation: navClose 600ms both;

          .nav-button-close {
            opacity: 0;
          }

          .flexbox-nav-inner {
            .flexbox-nav {
              opacity: 0;
            }
          }
        }
      }

      .nav-button-close {
        position: absolute;
        z-index: 22;
        display: none;
        width: 100%;
        top: 20px;
        right: 20px;

        &.hidden {
          display: none;
        }

        .container {
          display: flex;
          justify-content: flex-end;

          &:before,
          &:after {
            content: none;
          }
        }

        button {
          outline: none;
          border: none;
          background: none;
          padding: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .button-bar {
            display: block;
            position: absolute;
            width: 20px;
            height: 2px;
            background: #222;

            &:nth-child(1) {
              transform: rotate(45deg);
            }

            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }

      .flexbox-nav-inner {
        width: 100%;

        .flexbox-nav {
          width: 100%;
          display: flex;
          justify-content: center;

          li {
            padding: 0 25px;

            &.nav-collapse {
              display: flex;
              flex-direction: column;
              align-items: center;

              .collapse-list {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 5px;

                li {
                  padding: 5px 0;

                  a {
                    font-size: 15px;
                  }
                }
              }
            }

            a {
              color: #444444;
              font-size: 14px;
              letter-spacing: 0.5px;
              transition: color 350ms;

              &:hover {
                color: @color1;
              }
            }
          }
        }
      }
    }

    .flexbox-contact {
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
      z-index: 22;
      width: 30%;

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        right: 0;
        top: -70px;
        height: 120px;
        width: 110%;
        background-color: rgba(@color1, 0.8);
      }

      &.hidden {
        display: none;
      }

      a {
        color: #fff;

        span {
          font-size: 13px;
          font-weight: 700;
          opacity: 1;
          letter-spacing: 0.5px;
          transition: color 350ms;
        }

        &:hover span {
          color: #444;
        }
      }
    }
  }
}

.nav-button {
  margin-left: -10px;
  padding: 10px;
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  cursor: pointer;

  &.hidden {
    display: none;
  }

  .button-bar {
    height: 1px;
    background-color: #fff;
    width: 25px;
    border-radius: 10px;
    transition: all 350ms;

    &:nth-child(2) {
      margin-top: 5px;
    }

    &:nth-child(3) {
      margin-top: 5px;
    }
  }

  &:hover,
  &:focus {
    .button-bar {
      background: #000;
      width: 20px;

      &:nth-child(2) {
        border-color: @color1;
        background: @color1;
        width: 25px;
      }

      &:nth-child(3) {
        width: 28px;
      }
    }
  }
}

.btn {
  background: none;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  white-space: normal;
  margin-top: 10px;

  &.btn-default,
  .btn-default-dark {
    color: #222;
    display: flex;
    justify-content: flex-start;
    display: inline-block;
    align-self: flex-start;

    span {
      display: inline-block;
      width: 22px;
      height: 1.5px;
      transform: translate(-7px, -3px);
      background-color: @color1;
      transition: all 350ms;
    }

    &:hover {
      span {
        width: 30px;
      }
    }
  }

  &.btn-default-dark {
    color: #fff;
  }
}

.page-heading {
  background-color: #f5f5f5;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    width: 70%;
    margin: 0 auto;
    font-size: 60px;
    text-align: center;
  }

  p {
    font-size: 22px;
    margin-top: 10px;
  }
}

.section-heading {
  position: absolute;
  z-index: 11;
  left: 0;
  top: 40px;
  left: 30px;
  border-left: 1px solid #bbb;
  padding-left: 10px;
  height: 60px;

  span {
    color: #222;
    font-size: 15px;
    font-weight: 300;
    display: block;
  }

  h2 {
    margin-top: 10px;
    color: #222;
    font-size: 28px;
    line-height: 20px;
    text-transform: uppercase;
  }

  &.light {
    h2,
    span {
      color: #fff;
    }
  }
}

.heading-mobile {
  display: none;
  margin-bottom: 20px;

  h3 {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
  }
}

.heading-element {
  h2 {
    text-align: center;
    font-size: 45px;
    font-weight: 600;
  }
}

#return-to-top {
  position: fixed;
  z-index: 999;
  bottom: 50px;
  right: 50px;
  background-color: rgba(@color1, 0.7);
  width: 45px;
  height: 45px;
  text-decoration: none;
  display: none;
  transition: all 0.3s ease;
  cursor: pointer;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      width: 22px;
    }
  }

  &:hover {
    background-color: @color1;
  }
}

.owl-controls {
  .owl-dots {
    transform: rotate(90deg);
    display: flex;
    justify-content: center;
    align-items: center;

    .owl-dot {
      span {
        background: transparent;
        border: 1px solid #fff;
        width: 10px;
        height: 10px;
      }

      &.active {
        span {
          background: @color1;
          border: none;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

.color-element {
  position: absolute;
  z-index: -1;
  left: -15px;
  top: -15px;
  width: 150px;
  height: 150px;
  background-color: @color1;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  transition: all 450ms;
}

.link-color {
  color: #fff;
  position: relative;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  width: 300px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;

  .link-color-element {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-color: @color1;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.1);
    transition: all 350ms;
  }

  &:hover {
    color: #fff;

    .link-color-element {
      width: 305px;
      height: 85px;
    }
  }
}

.news-wrapper {
  display: flex;

  .navigation {
    width: 15%;

    p {
      font-size: 17px;
      text-transform: uppercase;
      margin: 0 0 10px 0;
    }

    ul {
      flex-direction: column;
      border: none;

      li {
        padding: 10px 0;
        transition: all 300ms;

        a {
          color: #222;
          padding: 5px 0;
          transition: all 300ms;

          &.active {
            color: @color1;
          }
        }

        &:hover {
          transform: translatex(3px);

          a {
            color: @color1;
          }
        }
      }
    }
  }

  .news-content {
    width: 85%;
  }

  .news-description {
    width: 50%;
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 300;
      color: #222;
      margin-bottom: 10px;

      &:after {
        display: inline-block;
        transform: translate(10px, -8px);
        content: "";
        width: 25px;
        height: 1px;
        background-color: #222;
      }
    }

    p {
      font-size: 15px;
      line-height: 28px;
    }

    .date {
      font-size: 13px;
      margin-bottom: 10px;
      font-weight: 700;
    }
  }

  .news-img {
    width: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.pagination-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0 0 170px;

  li {
    padding: 5px;

    a {
      color: #222;
      padding: 10px;
    }

    &.active {
      background-color: @color1;

      a {
        color: #fff;
        font-weight: 700;
        font-size: 18px;
      }
    }
  }
}

section.section-welcome {
  height: 100vh;
  position: relative;

  .nav-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 555;

    &.sticky {
      position: fixed;
      transition: all 300ms linear;
      background-color: rgba(#000, 0.8);

      .nav-button {
        &:hover,
        &:focus {
          .button-bar {
            background: #fff;

            &:nth-child(2) {
              border-color: @color1;
              background: @color1;
            }
          }
        }
      }
    }

    .nav-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .header-flexbox {
        .flexbox-logo {
          width: auto;
        }
      }
    }
  }

  .welcome-background {
    position: relative;
    background: url(../img/backgrounds/bg.jpg) no-repeat;
    height: 100%;
    width: 100%;
    display: flex;
    background-size: cover;

    .owl-carousel {
      .animated {
        animation-duration: 800ms;
        animation-fill-mode: both;
      }
    }

    .owl-carousel-wrapper {
      height: 300px;
      width: 450px;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(0%, -50%);
      border-top: 2px solid rgba(255, 255, 255, 0.9);
      border-right: 2px solid rgba(255, 255, 255, 0.9);
      border-bottom: 2px solid rgba(255, 255, 255, 0.9);

      .owl-carousel-welcome {
        height: 100%;
        width: 100%;
        z-index: 1;
        position: relative;

        .owl-stage-outer,
        .owl-stage,
        .owl-item,
        .owl-item-wrapper {
          height: 100%;
        }

        .owl-item-wrapper {
          padding: 0 20px;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          p {
            font-size: 15px;
            margin-bottom: 10px;
            color: #fff;
            font-weight: 300;
          }

          h2 {
            color: #fff;
            font-size: 32px;
          }
        }

        .owl-controls {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(245%, -50%);

          .owl-nav {
            display: none;
          }
        }
      }

      .owl-element {
        position: absolute;
        z-index: 1;
        height: 88px;
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(-90deg);
        top: 104px;
        left: -194px;
        background-color: rgba(255, 255, 255, 0.7);
        border-top: 3px solid rgba(355, 355, 255, 0.9);
        border-left: 3px solid rgba(355, 355, 255, 0.9);
        border-right: 3px solid rgba(355, 355, 255, 0.9);

        p {
          color: @color1;
          font-size: 12px;
          font-weight: 800;
          text-transform: uppercase;
          letter-spacing: 1.5px;

          strong {
            font-weight: 800;
          }
        }
      }
    }

    .background-left-col {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      .list-social-media {
        display: flex;
        margin: 0 0 60px 30px;

        li {
          margin-right: 15px;

          .social-media {
            img {
              width: 40px;
            }
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    .background-right-col {
      width: 50%;
      height: 100%;
      background-color: rgba(#000, 0.55);
      display: flex;
      align-items: flex-end;

      h3 {
        margin: 0 0 70px 60px;
        font-size: 13px;
        line-height: 25px;
        letter-spacing: 2px;
        font-weight: 600;
        z-index: 1;
        color: #fff;
        text-transform: uppercase;
      }
    }
  }
}

section.section-about {
  position: relative;
  padding: 100px 0;

  .section-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .about-description {
      width: 45%;
      padding-left: 20px;

      h3 {
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 34px;

        strong {
          color: @color1;
          font-size: 34px;
        }
      }

      p:not(:first-of-type) {
        margin-top: 10px;
        font-size: 15px;
        line-height: 28px;
        text-align: justify;
      }
    }

    .about-image {
      position: relative;
      width: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &:hover .color-element {
        width: 120px;
        height: 120px;
      }
    }
  }
}

section.section-icons {
  padding: 60px 0;
  background-color: #f5f5f5;

  .section-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      h3 {
        text-align: center;
        margin-top: 20px;
        font-size: 15px;
        transition: color 350ms;
      }

      img {
        width: 40px;
      }

      .active {
        color: @color1;
      }
    }
  }
}

section.section-offer {
  position: relative;
  height: 600px;

  .section-background {
    position: relative;
    display: flex;
    height: 100%;
    background-color: rgba(#000, 0.75);

    .owl-carousel {
      .animated {
        animation-duration: 400ms;
        animation-fill-mode: both;
      }
    }

    .owl-carousel-wrapper {
      height: 450px;
      width: 100%;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .owl-carousel-offer {
        width: 100%;
        height: 100%;
        z-index: 1;

        .owl-stage-outer,
        .owl-stage,
        .owl-item,
        .owl-item-wrapper {
          height: 100%;
        }

        .item {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          .heading {
            width: 36%;
            height: 80%;
            padding: 25px;
            border: 2px solid #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h3 {
              color: #fff;
              font-size: 40px;
            }

            p {
              font-size: 15px;
              line-height: 26px;
              letter-spacing: 1.5px;
              color: #fff;
              font-weight: 300;
            }

            ul {
              text-align: left;
              padding: 20px 0 0 30px;

              li {
                padding: 5px 0;
                position: relative;
                transition: all 300ms;
                color: #fff;
                font-size: 15.5px;

                &:before {
                  position: absolute;
                  content: "";
                  width: 7px;
                  height: 7px;
                  background: @color1;
                  top: 14px;
                  left: -15px;
                  transition: all 250ms;
                }

                &:hover {
                  transform: translatex(3px);
                  color: lighten(@color1, 17%);
                }

                &:hover:before {
                  background: #fff;
                }
              }
            }
          }

          .description {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            margin-left: 30px;
            width: 32%;

            h3 {
              color: #fff;
              font-size: 18px;
              text-transform: uppercase;
              letter-spacing: 2px;
              font-weight: 300;
              line-height: 26px;
            }

            p {
              color: #fff;
              text-align: justify;
              font-size: 15px;
              line-height: 28px;
              margin-top: 10px;
            }
          }
        }

        .owl-controls {
          .owl-dots {
            position: absolute;
            right: 85px;
            bottom: 220px;
          }

          .owl-nav {
            width: 92%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            .owl-prev,
            .owl-next {
              background: transparent;
              transition: transform 350ms;

              span {
                font-size: 12px;
                letter-spacing: 1px;
                margin: 0 5px;
              }

              img {
                width: 30px;
                transition: opacity 350ms;
                opacity: 0.7;
              }

              &:hover img {
                opacity: 1;
              }
            }

            .owl-prev:hover {
              transform: translatex(-3px);
            }

            .owl-next:hover {
              transform: translatex(3px);
            }
          }
        }
      }
    }

    .background-left-col {
      width: 50%;
      height: 100%;
      opacity: 0.4;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .background-right-col {
      width: 50%;
      height: 100%;
      position: relative;
    }
  }
}

section.section-team {
  padding: 120px 0 100px 0;
  position: relative;

  .team-description {
    width: 75%;

    h3 {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 34px;

      strong {
        color: @color1;
        font-size: 34px;
      }
    }
  }

  .section-wrapper {
    margin-top: 60px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &:hover .color-element {
      width: 120px;
      height: 120px;
    }

    .person {
      position: relative;
      width: 32.7%;
      height: 300px;
      margin: 3px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: contrast(70%);
        transition: all 450ms;
        object-position: 0 20%;
      }

      .person-description {
        position: absolute;
        bottom: 0;
        left: 0;
        color: #fff;
        line-height: 18px;
        background-color: rgba(0, 0, 0, 0.5);
        letter-spacing: 1px;
        opacity: 0;
        transition: all 350ms ease;
        height: 0;
        width: 100%;
        padding: 20px 8px 5px 15px;

        span.name {
          font-size: 15px;
          font-weight: 600;
          display: block;
          text-transform: uppercase;

          &:after {
            display: inline-block;
            transform: translate(5px, -5px);
            content: "";
            width: 15px;
            height: 1px;
            background-color: #fff;
          }
        }

        span.position {
          font-size: 14px;
          display: block;
          margin-top: 5px;
        }

        p {
          margin-top: 10px;
          font-size: 14px;
          display: none;

          a {
            color: @color1;
          }
        }
      }

      &:hover img {
        filter: contrast(100%);
      }

      &:hover {
        .person-description {
          opacity: 1;
          height: 57%;

          p {
            display: block;
          }
        }
      }

      .link {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        transition: all 350ms ease;

        .name {
          position: absolute;
          background-color: transparent;
          color: #222;
          text-transform: uppercase;
          letter-spacing: 1px;
          opacity: 0;
          left: 25%;
          top: 45%;
        }

        img {
          width: 60px;
          object-fit: contain;
          filter: contrast(100%);
          object-position: center;
        }

        &:hover {
          img {
            opacity: 0;
          }

          .name {
            opacity: 1;
          }
        }
      }
    }
  }
}

.owl-carousel-wrapper {
  display: flex;
  align-items: center;

  .owl-carousel-partners {
    height: 150px;
    margin-top: 50px;

    .owl-stage-outer,
    .owl-stage,
    .owl-item,
    .owl-item-wrapper {
      height: 100%;
    }

    .owl-controls {
      display: none;
    }

    .owl-item {
      display: flex;
      justify-content: center;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #222;

        img {
          width: 170px;
          opacity: 0.7;
          transition: opacity 350ms;
        }

        p {
          text-transform: uppercase;
          text-align: center;
          letter-spacing: 1px;
          font-size: 13px;
          opacity: 0;
          transition: all 350ms;
          margin-top: 15px;
        }

        &:hover img,
        &:hover p {
          opacity: 1;
        }
      }
    }
  }
}

section.section-partners {
  position: relative;
  padding: 50px 0;
  background-color: #f5f5f5;
}

section.section-news {
  position: relative;
  padding: 150px 0 100px 0;

  .news-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .news {
    display: flex;
    flex-direction: column;
    width: 32%;
    max-height: 320px;
    margin: 5px;

    &.news-lg {
      width: 100%;
      flex-direction: row;
      margin: 0 5px 10px 5px;

      .news-description {
        background-color: transparent;
        width: 50%;
        padding: 0 0 0 40px;

        h3 {
          color: #222;
          margin-bottom: 10px;
          font-size: 26px;
        }

        p {
          font-size: 15px;
          line-height: 28px;
        }

        .date {
          font-size: 13px;
        }

        &:hover {
          background-color: transparent;

          .btn-default span {
            background-color: @color1;
          }
        }
      }
    }

    .news-description {
      width: 100%;
      height: 100%;
      padding: 20px;
      background-color: #f5f5f5;
      transition: all 350ms;
      justify-content: space-between;

      h3 {
        font-size: 20px;
      }

      p {
        font-size: 13px;
        line-height: 22px;
        margin-top: 10px;
      }

      .date {
        font-size: 11px;
      }

      &:hover {
        background-color: rgba(@color1, 0.6);

        .btn-default span {
          background-color: #222;
        }
      }
    }
  }
}

section.o-nas-section-content {
  padding-bottom: 140px;
  overflow: hidden;

  .heading-element {
    padding: 80px 0 50px 0;
  }

  .section-content {
    .section-element {
      padding-top: 20px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      &.reverse {
        flex-direction: row-reverse;
      }

      .text {
        width: 50%;
        text-align: justify;

        h2 {
          font-weight: 600;
          margin-bottom: 20px;
        }

        p {
          margin-top: 10px;
          font-size: 15px;
          line-height: 28px;
        }
      }

      .img {
        position: relative;
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;

        .color-element {
          width: 35px;
          height: 30px;
          left: 20%;

          .sm-color-element {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-150%, 100%);
            background-color: @color1;
            width: 25px;
            height: 22px;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
              0 3px 1px -2px rgba(0, 0, 0, 0.12);
          }
        }

        img {
          width: 140px;
          opacity: 0.7;
        }
      }
    }
  }

  .section-team {
    padding-top: 80px;

    h2 {
      font-size: 45px;
      font-weight: 600;
    }

    .team-wrapper {
      padding-top: 60px;
      display: flex;
      flex-direction: column;

      .person {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:nth-of-type(even) {
          flex-direction: row-reverse;

          .person-img {
            &:before {
              left: -20%;
              right: 0;
            }
          }

          .person-description {
            padding: 0 40px 0 0;
          }
        }

        &:not(:first-of-type) {
          padding-top: 100px;
        }

        .person-img {
          position: relative;
          width: 30%;
          height: 400px;

          &:before {
            content: "";
            position: absolute;
            top: -20px;
            z-index: -1;
            background-color: #f5f5f5;
            width: 1500px;
            height: 110%;
            right: -20%;
          }

          img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
          }
        }

        .person-description {
          width: 60%;
          padding: 0 0 0 40px;

          span.name {
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 24px;
            display: block;

            &:after {
              display: inline-block;
              transform: translate(5px, -5px);
              content: "";
              width: 15px;
              height: 1px;
              background-color: #222;
            }
          }

          span.position {
            font-size: 14px;
            display: block;
          }

          p {
            margin-top: 20px;
            font-size: 15px;
            line-height: 26px;
            text-align: justify;

            a {
              color: @color1;
            }
          }

          .btn-default {
            margin-top: 30px;
          }
        }
      }
    }
  }

  .section-carreer {
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    h3 {
      font-size: 36px;
      line-height: 46px;
      text-align: center;
    }
  }

  .section-gallery {
    display: flex;
    align-items: center;
    justify-content: center;

    .items-wrapper {
      width: 38%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: 10px;

      .item {
        &:first-of-type,
        &:last-of-type {
          .item-overlay {
            background-color: rgba(@color1, 0.5);
            opacity: 1;

            &:hover img {
              opacity: 1;
            }
          }
        }
      }
    }

    .item {
      position: relative;
      width: 49%;
      height: 145px;
      margin-top: 8px;

      &.big {
        width: 60%;
        height: auto;
        padding-top: 0;

        .item-overlay {
          img {
            width: 60px;
            height: 60px;
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
      }

      .item-overlay {
        position: absolute;
        background-color: rgba(#000, 0.5);
        z-index: 1;
        opacity: 0;
        width: 100%;
        height: 100%;
        transition: opacity 350ms;

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 11;
          width: 35px;
          height: 35px;
          opacity: 0;
          transition: opacity 350ms;
        }
      }

      &:hover .item-overlay,
      &:hover .item-overlay img {
        opacity: 1;
      }
    }
  }
}

section.offer-page-section-content {
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;

  .img-absolute {
    position: fixed;
    z-index: -1;
    width: 300px;
    left: -70px;
    bottom: 20%;
  }

  .heading-element {
    margin-top: 80px;
  }

  .section-cta {
    position: relative;
    display: flex;
    align-items: center;
    margin: 60px 0 40px 0;

    .color-element {
      width: 35px;
      height: 30px;
      left: 15%;

      .sm-color-element {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-150%, 100%);
        background-color: @color1;
        width: 25px;
        height: 22px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
          0 3px 1px -2px rgba(0, 0, 0, 0.12);
      }
    }

    a {
      font-size: 24px;
      line-height: 36px;
      font-weight: 600;
      padding: 0 250px;
      color: #222;
      display: block;
      transition: color 350ms;
      text-align: center;
      width: 100%;

      &:hover {
        color: @color1;
      }
    }
  }

  .section-content {
    .description {
      padding-top: 60px;

      h2 {
        font-size: 22px;
        margin-bottom: 20px;
      }

      p {
        text-align: justify;
        margin-top: 20px;
        font-size: 15px;
        line-height: 28px;
      }
    }

    .section-element {
      margin-top: 60px;

      &:nth-of-type(even) {
        .section-text {
          h4 {
            text-align: right;
          }
        }
      }

      .section-text {
        h4 {
          font-size: 36px;
          line-height: 46px;
          margin-bottom: 30px;
          text-align: left;
        }

        p {
          text-align: justify;
          font-size: 15px;
          line-height: 28px;
        }

        .btn {
          margin-top: 20px;
        }

        .elements-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 30px;
        }

        .element {
          position: relative;
          margin: 3px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          width: 32.7%;
          height: 200px;
          padding: 40px 30px;
          background-color: #f5f5f5;
          transition: background-color 350ms;
          cursor: default;

          span {
            display: block;
            font-weight: 700;
            margin-bottom: 5px;
            text-transform: uppercase;
            letter-spacing: 1px;
          }

          .number {
            position: absolute;
            left: 0;
            top: -20px;
            font-size: 75px;
            font-weight: 700;
            color: rgba(#000, 0.3);
          }

          p {
            text-align: center;
          }

          &:first-of-type,
          &:last-of-type {
            background-color: rgba(@color1, 0.6);

            &:hover {
              background-color: rgba(@color1, 0.8);
            }
          }

          &:hover {
            background-color: #ddd;
          }
        }
      }
    }
  }
}

section.aktualnosci-section-content {
  padding-bottom: 100px;

  .news-wrapper {
    display: flex;
    padding-top: 70px;

    .news-content {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .news-description {
        padding-left: 0;
        margin-left: 50px;

        h3 {
          font-size: 20px;
          line-height: 30px;

          &:after {
            transform: translate(10px, -6px);
          }
        }

        .btn-default {
          span {
            transform: translate(-7px, -4px);
          }
        }
      }

      .news-item {
        width: 100%;
        display: flex;

        &:not(:first-of-type) {
          margin-top: 30px;
        }
      }
    }
  }
}

section.aktualnosc-section-content {
  padding-bottom: 120px;

  .section-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 60px;

    .description {
      width: 55%;

      p {
        font-size: 15px;
        line-height: 28px;

        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }

      a {
        color: @color1;
      }

      ul {
        padding: 10px 0 0 17px;

        li {
          position: relative;
          padding: 5px 0;
          font-size: 15px;

          &:before {
            position: absolute;
            content: "";
            width: 7px;
            height: 7px;
            background: @color1;
            top: 12px;
            left: -15px;
            transition: all 250ms;
          }
        }
      }
    }

    .img {
      position: relative;
      width: 40%;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      img {
        width: 100%;
        height: 70%;
        object-fit: cover;
      }

      &:hover .color-element {
        width: 120px;
        height: 120px;
      }

      .btn-default {
        font-size: 18px;

        span {
          transform: translate(-7px, -5px);
        }
      }
    }
  }
}

section.faq-section-content {
  padding-bottom: 120px;
  position: relative;
  overflow: hidden;

  .faq-content {
    padding-top: 60px;
    width: 75%;

    h2 {
      margin-bottom: 20px;
    }

    .faq-item {
      margin: 0 0 30px 15px;

      h3 {
        position: relative;
        display: block;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 10px;
        transition: all 250ms;

        &:before {
          position: absolute;
          content: "";
          width: 7px;
          height: 7px;
          background: @color1;
          top: 10px;
          left: -15px;
        }
      }

      p {
        font-size: 15px;
        line-height: 28px;
        text-align: justify;

        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }

      &:hover {
        span {
          color: @color1;
        }
      }
    }
  }

  .img-absolute {
    position: absolute;
    right: -8%;
    bottom: 30%;
    opacity: 0.3;
    width: 400px;
    height: 400px;
  }
}

section.static-page-section-content {
  padding-bottom: 140px;

  .heading-element {
    margin-top: 30px;
  }

  .section-content {
    .description {
      padding-top: 60px;

      h2 {
        font-size: 22px;
        margin-bottom: 20px;
      }

      h3 {
        font-size: 20px;
        font-weight: 700;
        margin-top: 20px;
      }

      p {
        text-align: justify;
        margin-top: 20px;
        font-size: 15px;
        line-height: 28px;
      }

      ul {
        padding: 20px 0 0 17px;

        li {
          position: relative;
          padding: 5px 0;
          font-size: 15px;
          line-height: 28px;

          &:before {
            position: absolute;
            content: "";
            width: 7px;
            height: 7px;
            background: @color1;
            top: 12px;
            left: -15px;
            transition: all 250ms;
          }
        }
      }

      .element-image {
        width: 100%;
        padding: 20px 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .btn-default {
        font-size: 15px;

        span {
          transform: translate(-7px, -6px);
        }
      }
    }
  }

  .owl-carousel-wrapper {
    background-color: #f5f5f5;
    height: 180px;
    padding-top: 30px;
    margin-top: 50px;
    display: flex;
    align-items: center;

    .owl-carousel-partners {
      width: 70%;
      margin: 0 auto;
    }
  }

  .section-cooperation {
    padding-top: 60px;
    display: flex;
    gap: 20px;
    align-items: flex-start;
    justify-content: space-between;

    .section-text {
      width: 45%;

      h3 {
        margin-bottom: 20px;
        font-size: 17px;
        line-height: 34px;

        strong {
          color: @color1;
          font-size: 34px;
        }
      }

      p {
        font-size: 15px;
        text-align: justify;

        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }

      ul {
        padding: 10px 0 0 20px;

        li {
          padding: 3px 0;
        }
      }

      .link-color {
        margin: 40px 0 0 0;
      }
    }
  }
}

section.kontakt-section-content {
  padding-bottom: 80px;

  .section-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    h2 {
      font-size: 28px;
      line-height: 40px;

      strong {
        color: @color1;
        font-size: 34px;
      }
    }

    h3 {
      font-size: 22px;
      font-weight: 700;
      letter-spacing: 1px;
      margin-bottom: 20px;
    }

    .section-information {
      margin-top: 30px;
      display: flex;
      align-items: flex-start;

      .information-column {
        width: 50%;

        address {
          font-size: 15px;
        }

        a {
          color: #222;
          font-weight: 600;
          font-size: 15px;
          transition: color 350ms;
          margin-left: 5px;

          span {
            font-size: 13.5px;
            font-weight: normal;
          }

          &:hover {
            color: @color1;
          }
        }

        p {
          margin-top: 10px;
        }

        &:nth-of-type(2) {
          margin-top: 5px;
        }
      }
    }

    .section-map {
      padding-top: 50px;
      display: flex;
      justify-content: space-between;

      .map-wrapper {
        width: 48%;

        iframe {
          height: 100%;
          width: 100%;
        }
      }

      .section-form {
        padding: 20px 30px;
        margin: 0;
        width: 50%;

        h3 {
          margin-bottom: 0;
        }
      }
    }

    .heading {
      padding-top: 20px;
    }

    .section-team {
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      .person {
        width: 18%;
        margin: 8px;
        display: flex;
        flex-direction: column;

        .image {
          height: 60%;
          width: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .contact {
          height: 35%;
          margin-top: 10px;

          .name {
            font-weight: 600;
            font-size: 15px;
            text-transform: uppercase;

            &:after {
              display: inline-block;
              transform: translate(5px, -5px);
              content: "";
              width: 15px;
              height: 1px;
              background-color: #000;
            }
          }

          .position {
            font-size: 15px;
            margin-bottom: 10px;
            display: block;
          }

          a {
            color: #222;
            font-weight: 600;
            font-size: 14.5px;
            transition: color 350ms;

            span {
              font-size: 13px;
              font-weight: normal;
            }

            &:hover {
              color: @color1;
            }
          }
        }
      }
    }
  }
}

// LP - Faktoring

.hide-animation(@translate) {
  opacity: 0;
  transform: @translate;
}

.btn {
  font-size: 15px;
  letter-spacing: 0.5px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  transition: all 350ms;

  &.btn-color {
    background-color: @color1;
    color: #fff;
    max-width: 250px;
    margin: 0;
  }

  &.btn-pulse {
    box-shadow: 0 0 0 0 @color1;
    animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);

    &:hover {
      background-color: darken(@color1, 15%);
    }
  }

  @keyframes pulse {
    to {
      box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
    }
  }

  &:hover {
    &.btn-color {
      background-color: darken(@color1, 5%);
    }
  }
}

nav.faktoring-section-header {
  position: relative;
  z-index: 22;
  background-color: #ffff;
  transition: all 350ms;

  &.fixed {
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    width: 100%;
    animation: fixed-nav 550ms ease;

    @keyframes fixed-nav {
      from {
        top: -100px;
      }

      to {
        top: 0;
      }
    }
  }

  .header-flexbox {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flexbox-logo {
      img {
        width: 150px;
      }
    }

    .flexbox-nav-wrapper {
      .nav-button-close {
        position: absolute;
        display: none;
        width: 100%;
        top: 20px;
        left: 0;

        .container {
          display: flex;
          justify-content: flex-end;

          &:before,
          &:after {
            content: none;
          }
        }

        button {
          outline: none;
          border: none;
          background: none;
          padding: 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          .button-bar {
            display: block;
            position: absolute;
            width: 30px;
            height: 2px;
            background: #222;

            &:nth-child(1) {
              transform: rotate(45deg);
            }

            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }

      .flexbox-nav-inner {
        .flexbox-nav {
          display: flex;
          justify-content: space-between;
          align-items: center;

          li {
            &.color-link {
              a {
                color: @color1;
                font-weight: 700;
              }
            }

            a {
              color: #000;
              opacity: 0.8;
              font-weight: 600;
              font-size: 15px;
              letter-spacing: 0.5px;
              transition: all 350ms;

              &.btn-color-1 {
                color: #fff;
              }
            }

            &:hover {
              a {
                color: @color1;

                &.btn-color-1 {
                  color: #fff;
                }
              }
            }

            &:not(:last-of-type) {
              margin-right: 40px;
            }
          }
        }
      }
    }

    .nav-button {
      padding: 10px;
      border: none;
      background: none;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      outline: none;
      display: none;

      .button-bar {
        height: 2px;
        width: 24px;
        background: #000;
        border-radius: 10px;
        transition: all 0.4s;

        &:nth-child(2) {
          margin-top: 3.5px;
          width: 27px;
        }

        &:nth-child(3) {
          margin-top: 3.5px;
          width: 30px;
        }
      }

      &:hover,
      &:focus {
        .button-bar {
          width: 30px !important;
        }
      }
    }
  }
}

.faktoring-section-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 15px;
}

.hide-animation(@translate) {
  opacity: 0;
  transform: @translate;
}

section.faktoring-section-welcome {
  position: relative;
  background-color: #f8f8fa;
  padding: 50px 0;

  .blob-bg {
    position: absolute;
    width: 1500px;
    top: 50%;
    left: 50%;
    transform: translate(-95%, -50%);
  }

  .welcome-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .faktoring-wrapper-form {
    scroll-margin-top: 200px;
    width: 91%;
    margin: 0 auto;

    &.hide {
      display: none;
    }
  }

  .wrapper-text {
    position: relative;
    z-index: 1;
    width: 65%;
    padding-left: 50px;

    .text-content {
      transition: all 1000ms;

      &.hide {
        .hide-animation(translateX(-80px));
      }
    }

    h2 {
      font-size: 90px;
      line-height: 80px;
      font-weight: 800;
      margin-bottom: 20px;
      color: @color1;
    }

    h3 {
      font-size: 45px;
      line-height: 50px;
      font-weight: 600;

      span {
        color: @color1;
      }
    }

    .element {
      transition: all 1000ms;
      position: relative;
      padding: 5px 0 5px 80px;

      &:before {
        position: absolute;
        top: 11.5px;
        left: 38px;
        z-index: 1;
        content: "";
        display: inline-block;
        background: url("../img/icons/checked.svg") no-repeat;
        background-size: 15px 15px;
        width: 15px;
        height: 15px;
      }

      &:after {
        position: absolute;
        z-index: -1;
        top: 8px;
        left: 35px;
        background-color: @color1;
        content: "";
        width: 22px;
        height: 22px;
        display: inline-block;
      }

      &.hide {
        .hide-animation(translateX(-80px));
      }
    }

    p {
      font-size: 18px;
      letter-spacing: 0.5px;
      opacity: 0.8;
      font-weight: 600;
    }

    .btn-color {
      margin: 30px 0 0 35px;
      transition: all 1000ms;

      &.hide {
        .hide-animation(translateX(-80px));
      }
    }
  }

  .wrapper-image {
    position: relative;
    width: 30%;
    transition: all 1s;
    height: 65vh;

    img {
      max-width: 400px;
    }
  }
}

section.faktoring-section-about {
  scroll-margin-top: 60px;
  padding-top: 100px;
  background-color: #f8f8fa;
  overflow: hidden;

  &.company {
    padding: 60px 0;
    background-color: #fff;

    .wrapper-content {
      display: flex;
      flex-direction: column;
      text-align: center;

      header {
        width: 100%;
        padding: 0;
      }

      p {
        font-size: 15px;
        line-height: 26px;
        letter-spacing: 0.5px;
        opacity: 0.8;
        font-weight: 300;
        max-width: 1000px;
        margin: 0 auto;

        &:not(:first-of-type) {
          margin: 15px auto 0;
        }
      }

      .btn-color {
        margin-top: 40px;
      }
    }
  }

  .wrapper-content {
    display: flex;
    align-items: center;

    header {
      width: 45%;
      padding-right: 50px;

      p {
        font-size: 15px;
        line-height: 26px;
        letter-spacing: 0.5px;
        opacity: 0.8;
        font-weight: 300;

        &:not(:first-of-type) {
          margin-top: 15px;
        }
      }

      h2 {
        margin-bottom: 30px;
        font-size: 38px;
        font-weight: 600;

        strong {
          color: @color1;
        }
      }
    }

    .content-description {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 55%;
      gap: 20px;

      .box-inner {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 48%;
        text-align: center;
        padding: 25px;
        background-color: #ffffff;
        box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.05);

        .icon {
          background-color: @color1;
          padding: 10px;
          width: 50px;
          height: 50px;
          margin-bottom: 20px;

          img {
            width: 30px;
          }
        }

        h3 {
          font-size: 15px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          margin-bottom: 15px;
          font-weight: 600;
        }

        p {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.5px;
          opacity: 0.8;
          font-weight: 300;
        }
      }
    }
  }
}

section.faktoring-section-clients {
  padding-top: 100px;
  background: linear-gradient(180deg, #f8f8f8 5%, rgba(243, 245, 245, 0) 100%);

  header {
    text-align: center;

    h2 {
      margin-bottom: 30px;
      font-size: 38px;
      font-weight: 600;

      strong {
        color: @color1;
      }
    }
  }

  .wrapper-content {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    .content-element {
      text-align: center;
      width: calc(100% / 4);

      img {
        width: 50px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: 600;
      }

      p {
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.5px;
        opacity: 0.8;
        font-weight: 300;
      }
    }
  }
}

section.faktoring-section-advantages {
  padding: 100px 0;
  background-color: #ffff;

  header {
    text-align: center;

    h2 {
      margin-bottom: 30px;
      font-size: 38px;

      strong {
        color: @color1;
      }
    }
  }

  .wrapper-content {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;

    .content-text {
      width: 50%;

      ul {
        li {
          position: relative;
          padding: 15px 0 15px 50px;
          line-height: 30px;
          opacity: 0.8;
          font-weight: 300;

          strong {
            display: block;
            font-size: 20px;
            margin-bottom: 10px;
            font-weight: 600;
          }

          &:after {
            position: absolute;
            left: 0;
            top: 18px;
            background-color: @color1;
            content: "";
            width: 25px;
            height: 25px;
            display: inline-block;
          }

          &:before {
            position: absolute;
            z-index: 1;
            left: 4px;
            top: 18px;
            display: inline-block;
            content: url(../img/icons/checked.svg);
            background-size: 16px 16px;
            display: inline-block;
            width: 16px;
            height: 16px;
          }
        }
      }

      .btn-color {
        margin-top: 30px;
      }
    }

    .content-images {
      width: 42%;

      img {
        position: sticky;
        top: 100px;
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
        max-height: 300px;
        object-fit: cover;
        box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.1);
      }
    }
  }
}

section.faktoring-section-testimonials {
  padding: 40px 0 60px;
  background-color: #f8f8f8;

  .owl-carousel-testimonials {
    width: 100%;

    .owl-stage-outer,
    .owl-stage,
    .owl-item {
      height: 100%;
    }

    .owl-item {
      transition: all 0.8s;
      opacity: 0.25;
      transform: scale(0.85);

      .item {
        padding: 50px 20px 10px;
        text-align: center;

        header {
          position: relative;
          display: inline-block;
          margin-bottom: 15px;

          &:before {
            position: absolute;
            z-index: -1;
            content: "";
            width: 50px;
            height: 50px;
            background-color: @color1;
            top: -25%;
            right: 0;
          }

          h2 {
            font-size: 22px;
            margin: 0;
          }

          span {
            display: block;
            margin-top: 10px;
            font-size: 14px;
          }
        }

        p {
          font-size: 15px;
          line-height: 24px;
          letter-spacing: 0.5px;
          opacity: 0.8;
          font-weight: 300;
        }
      }

      &.center {
        opacity: 1;
        transform: scale(1.05);
      }
    }

    .owl-dots {
      margin-top: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transform: none;

      .owl-dot {
        margin: 5px;
        height: 3px;
        width: 20px;
        border-radius: 0;
        background-color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        transition: all 0.4s;
        outline: none;

        span {
          opacity: 0;
        }

        &.active {
          background-color: @color1;
          width: 40px;

          span {
            background-color: #000;
          }
        }
      }
    }
  }
}

.faktoring-wrapper-form {
  position: relative;
  background-color: #000;
  padding: 20px 20px 0;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);

  &.hide {
    display: none;
  }

  header {
    text-align: center;
    color: #fff;

    h3 {
      margin-bottom: 10px;
      font-size: 22px;
    }

    p {
      color: #fff;
      margin-bottom: 10px;
    }
  }

  .form-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;

    .input-service {
      width: calc(100% / 4 + 30px);
    }
  }

  input,
  textarea {
    background-color: #f8f8fa;
  }

  textarea,
  input {
    transition: all 0.2s;
    border: 1px solid @color1;
    border-radius: 0;
    height: 45px;
    margin: 8px 0;
    width: 100%;

    &:focus {
      outline: none;
      border: 1px solid @color1;
    }
  }

  input::placeholder {
    font-size: 13px;
    text-align: center;
  }

  textarea {
    height: 100px;
  }

  .checkbox-wrapper {
    .radio-box {
      span {
        color: #fff;
      }
    }
  }
}

.checkbox-wrapper {
  position: relative;
  cursor: pointer;
  display: block;
  margin: 10px 0 0;
  height: 35px;
  text-align: center;

  .radio-box {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    text-transform: none;
    color: #fff;
    text-align: left;
    transition: all 0.3s;
    padding: 0;
    font-weight: 500;
    width: 100%;

    &:focus {
      border: none;
    }

    &:before {
      content: "";
      min-width: 10px;
      max-width: 10px;
      height: 10px;
      display: block;
      background: url("../img/icons/checkbox.svg") no-repeat;
      margin-right: 10px;
      transition: all 0.3s;
    }

    span {
      font-size: 11px;
      width: 100%;
      display: block;
    }
  }

  input {
    width: auto !important;
    height: auto;
    position: absolute;
    left: 0;
    opacity: 0;

    &:focus {
      box-shadow: none;
    }
  }
}

.checkbox-wrapper > input:checked + .radio-box {
  font-weight: 700;

  &:before {
    background: url("../img/icons/checkbox-checked.svg") no-repeat;
  }
}

section.faktoring-section-footer {
  padding-top: 20px;
  background-color: #000;
  text-align: center;

  .logo {
    width: 200px;
  }

  .faktoring-wrapper-form {
    margin-top: 20px;
    background: #222;
  }

  .footer-flexbox {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer-inner {
    display: flex;
    align-items: center;

    ul {
      display: flex;
    }

    h2 {
      font-size: 14px;
      color: #fff;
    }

    a {
      display: block;
      font-size: 14px;
      color: #fff;
      margin-left: 30px;
    }
  }
}

section.section-footer {
  background-color: #404040;
  position: relative;
  z-index: 444;

  .service-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translatey(-30px);

    img {
      height: 70px;
    }

    p {
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: 700;
      margin-top: 10px;
    }
  }

  .footer-flexbox {
    position: relative;
    display: flex;
    justify-content: space-between;

    .flexbox-service {
      margin-top: 20px;

      .heading {
        margin-top: 0;
        padding-bottom: 10px;
        font-size: 18px;
        color: #fff;
      }

      ul {
        li {
          margin-top: 5px;

          a {
            font-size: 14px;
            color: #fff;
            transition: color 350ms;

            span {
              color: #fff;
            }

            &:hover {
              color: @color1;

              span {
                color: #fff;
              }
            }
          }
        }
      }

      .list-social {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        li {
          margin-right: 10px;

          a {
            img {
              height: 25px;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .alert {
    position: relative;
    background: none;
    border: none;
    border-radius: 0;
    padding: 0;
    width: 90%;
    margin: 50px auto 0 auto;

    p {
      font-size: 14px;
      color: #fff;
      font-weight: 300;
      text-align: center;
    }

    .alert-links {
      margin-top: 10px;
      text-align: center;

      a {
        color: #fff;
        cursor: pointer;
        transition: all 0.3s;
        margin-right: 10px;
        font-size: 13px;
        font-weight: 500;

        &:hover {
          color: @color1;
        }
      }
    }
  }

  .footer-credits-wrapper {
    margin-top: 40px;
    padding: 25px 0;
    background: #f5f5f5;

    .footer-credits {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;

      p {
        font-size: 13px;
        color: #444;
        font-weight: 300;

        a {
          color: @color1;
          font-weight: 600;
        }
      }
    }
  }
}
